declare type fsType = typeof import('fs');
import BN from 'bn.js';
import {
	ABI,
	FieldValue,
	FullRowDataMap,
	RowDataMappedFromContractName,
	TableDataFromName,
	TableName,
	Tables,
	TS200TableInstance
} from '../../ts200DataTypes';
import { Abi, ContractObject } from '@truffle/contract-schema/spec';
import type { AbiItem } from 'web3-utils';
declare type AbiExceptPayable = Exclude<Abi, {type: "receive"; stateMutability: "payable"}[]>;
import type {
	AbiMemberType,
	TruffleContractConstructorType,
	TruffleContractTxResult,
	EventABI,
	NormalFnABI,
	SolidityType,
} from './truffleTypes';
//Can't do import {Web3} from 'web3'; b/c of error
//Module '"web3"' has no exported member 'Web3'.
//Did you mean to use 'import Web3 from "web3"' instead?ts(2614)
//Can't use this:
//import Web3Import from 'web3';
//const Web3 = Web3Import.Web3;
//because of error: Property 'Web3' does not exist on type 'typeof Web3'.ts(2339) (same for .default)
import Web3 from 'web3';
// Importing *just* types on these 3, with issues importing w/code due to https://github.com/microsoft/TypeScript/issues/48390.
import type {
	WebsocketProvider as WebsocketProviderType, //can import w/code from 'web3-providers-ws'
	HttpProvider as HttpProviderType, //can import w/code from 'web3-providers-http'
	//Uncomment this if using IPC to improve efficiency:
	//IpcProvider as IpcProviderType, //can import w/code from 'web3-providers-ipc'
} from 'web3-core';
/* Using just the below produces a runtime error:
SyntaxError: Named export 'HttpProvider' not found.
The requested module 'web3-core' is a CommonJS module, which may not support all module.exports as named exports.
CommonJS modules can always be imported via the default export, for example using:
import pkg from 'web3-core';
const { WebsocketProvider, HttpProvider, IpcProvider, } = pkg;
*/
import {
	BlockNumber,
	AbstractProvider,
	provider as Web3Provider,
	Log as Web3ReceiptLog,
	Transaction,
	TransactionReceipt,
} from 'web3-core';
import {
	BlockTransactionString
} from 'web3-eth';
import { AppUI } from './appUI.js';
import { ContractLister } from './contractLister.js';
import { Chainreader } from './chainreader.js';
import { DateUtils } from './dateUtils.js';
import { PossiblyConsoleLogger } from '../../loggerConfiguration.js';
import { sharedConfiguration } from '../../config/sharedConfiguration.js';
import { serverConfiguration } from '../../config/serverConfiguration.js';
import type { ChainNumber } from '../../dataCache/cacheManager';

export interface Web3ProviderWithConnected extends AbstractProvider {
	connected: boolean; //not optional in this version
}

export type EmptyObject = Exclude<{}, {[index: string]: any} | {[index: number]: any}>;
export interface EmptyArray extends Array<any> {
	length: 0;
}
export interface EmptySet extends Set<any> {
	size: 0;
}
export interface SelfAwareZero extends Object {
	isZero: () => true;
}
export type EmptyHexString =
	'0' |
	'0x' |
	'0x0' |
	'0x00' |
	'0x000' |
	'0x0000' |
	'0x00000' |
	'0x000000' |
	'0x0000000' |
	'0x00000000' |
	'0x000000000' |
	'0x0000000000' |
	'0x00000000000' |
	'0x000000000000' |
	'0x0000000000000' |
	'0x00000000000000' |
	'0x000000000000000' |
	'0x0000000000000000' |
	'0x00000000000000000' |
	'0x000000000000000000' |
	'0x0000000000000000000' |
	'0x00000000000000000000' |
	'0x000000000000000000000' |
	'0x0000000000000000000000' |
	'0x00000000000000000000000' |
	'0x000000000000000000000000' |
	'0x0000000000000000000000000' |
	'0x00000000000000000000000000' |
	'0x000000000000000000000000000' |
	'0x0000000000000000000000000000' |
	'0x00000000000000000000000000000' |
	'0x000000000000000000000000000000' |
	'0x0000000000000000000000000000000' |
	'0x00000000000000000000000000000000' |
	'0x000000000000000000000000000000000' |
	'0x0000000000000000000000000000000000' |
	'0x00000000000000000000000000000000000' |
	'0x000000000000000000000000000000000000' |
	'0x0000000000000000000000000000000000000' |
	'0x00000000000000000000000000000000000000' |
	'0x000000000000000000000000000000000000000' |
	'0x0000000000000000000000000000000000000000' ;//would keep going if TS allowed regex/validator functions
export type EmptyType = undefined | null | false | 0 | '' | EmptyArray | EmptyHexString | SelfAwareZero | EmptySet | EmptyObject;
export type DOMString = string ; //For more readable match to MDN; see https://developer.mozilla.org/en-US/docs/Web/API/DOMString
export type HeadersObject = {[index: string] : string};
export type RequestBodyType = Document | XMLHttpRequestBodyInit | null | undefined; // see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/send
export type PossibleAJAXResponseType = ArrayBuffer | Blob | Document | object | DOMString;
export type PossibleAJAXResponseTypeSpecifier = (keyof PossibleAJAXResponseTypeMap); //XMLHttpRequestResponseType | undefined | 'file';
export interface XMLHttpRequestResponseTypeMap {
	//Ref: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
	'' : string; //default, same as text
	'arraybuffer' : ArrayBuffer;
	'blob' : Blob;
	'document' : Document;
	'json' : Object;
	'text' : string; //aka DOMString.
}
export interface PossibleAJAXResponseTypeMap extends XMLHttpRequestResponseTypeMap {
	'file' : File;
}
//Next line from https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
const httpMethodMap = {
	//The mapped boolean is currently meaningless & unused but the values could be changed to something
	//more meaningful in the future.
	'GET' : true,
	'HEAD' : true,
	'POST' : true,
	'PUT' : true,
	'DELETE' : true,
	'CONNECT' : true,
	'OPTIONS' : true,
	'TRACE' : true,
	'PATCH' : true,
};
export type HTTPMethod = keyof typeof httpMethodMap;
export type InitiatedContracts = (TS200TableInstance | undefined)[];
export interface ValueFromElementOrAncestor<V> {
	directObjectID: ChainNumber,
	value: V | undefined
}
interface StringIndexableObject {
	[index: string] : any;
}
interface ObjWithCloneFn<T> extends StringIndexableObject {
	clone: () => T;
}
interface ParsedLog {
	event: string;
	args: {[key: string] : string};
}
export const Common = {
	web3Provider: null as Web3Provider | null,
	web3ProviderForContracts: null as Web3Provider | null, //needs to be DIFFERENT than web3Provider due to https://github.com/ethereum/web3.js/issues/3573
	contracts: {} as {[index: string]: Truffle.Contract<Truffle.ContractInstance>},
	tables: {} as Tables, //{[index: string]: ContractInstance},
	userAccount: null as string | null,
	gasLimit: 6385876,//6385876 on MetaMask, 6721975 on Ganache //FOR TESTING ONLY
	gasPrice: 10000000000,//default value 10Gwei //FOR TESTING ONLY
	nodeOverwhelmRetries: 20,
	secondsWaitBetweenNodeOverwhelmRetries: 10,
	checkCount: {} as {[index: string]: number},
	logAfterCheckCount: 50,

	isUppercaseHTTPMethod: function(strIn: string) : strIn is HTTPMethod {
		return Object.keys(httpMethodMap).includes(strIn);
	},

	addHeaderToPage: function(
		web3: Web3
	) : Promise<void> {
		return new Promise(function(resolve, reject) {
			Common.fillHeaderFromFile(web3)
			.then(function() {
				let htmlPageTitle = $('#html_page_title').text();
				document.title = htmlPageTitle;
				resolve();
			}).catch(function(err: unknown) {
				console.log('Error setting header and/or HTML page title:',err);
				reject(err);
			});
		});
	},

	setContent: function(
		htmlFilename: string,
		callback: () => void
	) {
		$('#content').load(htmlFilename, function() {
			return callback();
		});
	},

	/** Relies on having a previously defined global ContractLister,
	 * but is only used in addHeaderToPage above, in browser.
	 */
	fillHeaderFromFile: function(web3: Web3) : Promise<void> {
		return new Promise(function(resolve, reject) {
			let dbName = ContractLister.dbName;
			let headerFileName = '../header';
			if(!Common.isEmpty(dbName)) {
				headerFileName += '-'+dbName;
			}
			headerFileName += '.html';
			Common.loadInto('#headerBar', headerFileName)
			.then(function() {
				//Successfully added app-specific header.
				$('#loginButton').on('click', function() {Common.loginNoRejection(web3);});
				return Common.selectAccount(web3, false);
			}).then(function(account: string) {
				if(account.length>0) {
					$('#loginButton').hide(); //Would be better to only hide if admin account found.
				}
				return resolve();
			}).catch(function(err: any) {
				if(err?.xhr?.status == 404) {
					console.log('Could not find app-specific header '+headerFileName+'. Adding generic header instead.');
					return resolve(Common.loadInto('#headerBar', '../header.html'));
				} else {
					console.log('Error in adding header:',err);
					return reject(err);
				}
			});
		});
	},

	hide: function(element: Element) {
		return Common.setDisplay(element, 'none');
	},

	setDisplay: function(
		element: Element,
		newDisplayValue: string
	) {
		if (!(element instanceof HTMLElement)) {
			console.warn('Could not set display of Element ', element, ' which is not an HTML element.');
			return;
		}
		//Based loosely on React-dom:
		if (typeof element.style.setProperty === 'function') {
		  element.style.setProperty('display', newDisplayValue, 'important');
		} else {
		  element.style.display = newDisplayValue;
		}
	},

	/** @returns {undefined}
	* This function may be used directly as a button onclick handler as it does not reject.
	*/
	loginNoRejection: function(web3: Web3) {
		Common.login(web3).catch(function(err: unknown) {
			//action already done in login.
		});
	},

	/** @returns {Promise} resolving to the account logged in with.
	*/
	login: function(web3: Web3) {
		return new Promise(function(resolve, reject) {
			Common.selectAccount(web3, true).
			then(function(account: string) {
				if(account.length>0) { //Would be better to call loginSuccess only if admin account found.
					AppUI.loginSuccess();
				}
				resolve(account);
			}).catch(function(e: any) {
				if(e?.code == 4001) {
					//User rejected the account connection request.
					console.log('Metamask account access denied. To use this interface, refresh the page and click Next, then Connect, on the "Connect With MetaMask" popup.');
					AppUI.loginUserRejection(e);
				} else {
					console.log(e);
				}
				reject(e);
			});
		});
	},

	/** @returns {Promise} resolving to accounts array.
	*/
	getAccounts: function(
		web3: Web3,
		promptForConnectionIfNone = false
	) : Promise<string[]> {
		return new Promise(function(resolve, reject) {
			web3.eth.getAccounts(function(error: unknown, accounts: string[]) {
				if (error) {
					console.error(error);
					reject(error);
				} else {
					let accountsPromise;
					if(accounts.length==0 && promptForConnectionIfNone) {
						accountsPromise = Common.requestAccountConnection(web3.currentProvider); //previously used Common.web3Provider
					} else {
						accountsPromise = Promise.resolve(accounts);
					}
					resolve(accountsPromise);
				}
			});
		});
	},

	providerHasRequest: function (
		web3Provider : Web3Provider
	) : web3Provider is AbstractProvider {
		return !(
			typeof web3Provider === 'undefined' ||
			typeof web3Provider === 'string' ||
			web3Provider === null ||
			!('request' in web3Provider) ||
			typeof web3Provider?.request === 'undefined'
		);
	},

	/** @returns {Promise} resolving to the list of connected accounts.
	where the return value is only actually used in checkMetaMaskAccount,
	interpreted as a boolean followed by action to get the accounts list.
	*/
	requestAccountConnection: function (
		web3Provider : Web3Provider
	) : Promise<string[]> {
		return new Promise(function(resolve, reject) {
			if(!Common.providerHasRequest(web3Provider) || (typeof web3Provider?.request === 'undefined')) {
				return reject('Web3 provider passed to requestAccountConnection cannot take requests.');
			}
			web3Provider.request({method: 'eth_requestAccounts'}).
			then(function(result : string[]) {
				resolve(result);
			}).catch(function(e) {
				//e.code == 4001 when user rejects account connection request.
				//Handle that up the call chain.
				reject(e);
			});
		});
	},

	loadInto: function(
		destSelector: string,
		whatToLoad: string
	) : Promise<void> {
		return new Promise(function(resolve, reject) {
			$(destSelector).load(whatToLoad, function(response, status, xhr) {
				if (status == 'error' ) {
					let errorObj = {
						status,
						xhr,
						response,
						message: 'Error fetching '+whatToLoad+': '+ xhr.status + ' ' + xhr.statusText,
					}
					reject(errorObj);
				} else {
					//Success assumed. Could check that
					//((status == 'success') || (status == 'notmodified'))
					//for more precision.
					resolve();
				}
			});
		});
	},

	unwrapIfQuoted: function(stringIn: string) : string {
		if(
			(stringIn.startsWith('"') && stringIn.endsWith('"')) ||
			(stringIn.startsWith("'") && stringIn.endsWith("'"))
		) {
			return stringIn.substring(1, stringIn.length-1);
		} else {
			return stringIn;
		}
	},

	addLogBlock: function() {
		$('#logBlock').load('../logBlock.html');
	},

	/**
	* @returns {string} representing the numeric id, or empty
	*/
	getIDFromPath: function() {
		const pathname = window.location.pathname;
		const firstDash = pathname.indexOf('-', 1);
		if(firstDash <=0) { //no id found
			return '';
		} else {
			return pathname.slice(firstDash+1);
		}
	},

	//Would be better to use IPC Provider for node.js apps as recommended by web3,
	//and webSocketProvider in-browser (HTTP provider deprecated.)
	//More on the type annotation for passing a class as a parameter for access to its static functions
	//https://stackoverflow.com/a/71431457/
	getWebSocketProvider: function(
		Web3ClassDefinition: typeof Web3
	) : Promise<WebsocketProviderType | HttpProviderType> {
		return new Promise(function(resolve, reject) {
			//Would be better to use keepalive options as documented for the WebsocketProvider
			let host = sharedConfiguration.blockchain.host;
			if(typeof serverConfiguration !== 'undefined') {
				//Using serverConfiguration to connect to websocket provider.
				host = serverConfiguration.servers.blockchain.host;
			}
			const port = sharedConfiguration.blockchain.webSocketPort;
			const options = {
				clientConfig: {
					//Otherwise, in TS200l, occasionally getting messages like
					//'Frame size of 1079516 bytes exceeds maximum accepted frame size'
					//even on events like childAdded and parentUpdated, when rebuilding
					//cache from events when there are many events. If this value,
					//which is approximately 100x higher than default, still isn't big enough,
					//you probably should be rebuilding from current state before turning on
					//the event listener anyway.
					//These values are as documented in https://github.com/ethereum/web3.js/tree/1.x/packages/web3-providers-ws
					maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
					maxReceivedMessageSize: 100000000, // bytes - default: 8MiB

					/* Produces lots of code 1006 errors, 'Connection dropped by remote peer.'
					// Useful to keep a connection alive
					keepalive: true,
					keepaliveInterval: 60000 // ms
					*/
				},

				/* In the past, this produced many 'CONNECTION ERROR: Provider started to reconnect before the response got received!' messages*/
				// Enable auto reconnection
				reconnect: {
					auto: true,
					delay: 5000, // ms
					maxAttempts: 5,
					onTimeout: false
				},
			};
			let useTLS = (host === '127.0.0.1') ? '' : 's';
			let retval = new Web3ClassDefinition.providers.WebsocketProvider('ws'+useTLS+'://'+host+':'+port, options);
			resolve(Common.waitForWebsocketProviderToConnect(retval));
		});
	},

	awaitPromisesResultsNoReject(promises: Promise<any>[]) {
		let retval = [];
		try {
			for(let promise of promises) {
				retval.push(Common.awaitPromiseResultsNoReject(promise));
			}
			return Promise.all(retval);
		} catch (err) {
			//e.g. if promises is undefined, incl. race condition where it becomes undefined
			//between the call guard and start of for loop
			return Promise.resolve([]);
		}
	},

	/**
	 * @param {Array<Promise>} promises
	 * @param {int} startIndex should be 0 when not called recursively
	 * @returns {Promise} resolving void when all promises have either resolved or rejected.
	 */
	awaitPromiseResolutionsNoReject(
		promises: Promise<any>[],
		startIndex = 0
	) : Promise<void> {
		if(!Array.isArray(promises) || startIndex >= promises.length) {
			return Promise.resolve();
		} else {
			return Common.awaitPromiseResultsNoReject(promises[startIndex]).then(function() {
				return Common.awaitPromiseResolutionsNoReject(promises, startIndex + 1);
			});
		}
	},

	awaitPromiseResultsNoReject(
		promise : Promise<any>
	) : Promise<any> {
		return new Promise(function(resolve, reject) {
			promise.then(function(val) {
				resolve(val);
			}).catch(function(err) {
				resolve(undefined); //https://github.com/microsoft/TypeScript/issues/47847
			});
		});
	},

	isInTotalShutdown() {
		return (typeof globalThis.inTotalShutdown !== 'undefined' && globalThis.inTotalShutdown);
	},

	waitForWebsocketProviderToConnect: function(
		provider: WebsocketProviderType | HttpProviderType,
		retriesLeft = 120
	) : Promise<WebsocketProviderType | HttpProviderType> {
		return new Promise(function(resolve, reject) {
			if(provider.connected) {
				//Provider is connected.
				return resolve(provider);
			} else if(retriesLeft > 0 && !Common.isInTotalShutdown()) {
				//Provider is not connected; will check again up to '+retriesLeft+' times after 500ms gaps each time.
				return setTimeout(function() {
					resolve(Common.waitForWebsocketProviderToConnect(provider, retriesLeft - 1));
				}, 500);
			} else {
				//Without this disconnect, the provider will hang around and prevent clean shutdown.
				//Typescript error on next line would be solved by https://github.com/ChainSafe/web3.js/pull/4833 merged in https://github.com/ChainSafe/web3.js/commit/d46d922e2d9714b6e8bd72db665f708f12e222ea not yet released
				provider.disconnect(); //See also chainEventListener.closeWebSocketProvider();
				//The code is numbered for the default WebSocket port used in this application (8546) + 404 (Not Found).
				if(Common.isInTotalShutdown()) {
					return reject({message: 'Error: provider did not connect before shutdown was triggered.', code: 8546404});
				} else {
					return reject({message: 'Error: provider did not connect after 2 minutes.', code: 8546404});
				}
			}
		});
	},

	setWeb3Provider: function(provider: Web3Provider | null) {
		Common.web3Provider = provider;
	},

	setWeb3ProviderForContracts: function(provider: Web3Provider | null) {
		Common.web3ProviderForContracts = provider;
	},

	/** Sets a global as its primary effect!
	 * @returns {Promise} resolving to the initiated web3 object.
	*/
	initWeb3: function(Web3param: typeof Web3) {
		return new Promise(async function(resolve, reject) {
			//Are we in the browser with a Metamask or similar provided provider?
			if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
				Common.setWeb3Provider(ethereum);
				//Intended for in-browser MetaMask use pending https://github.com/MetaMask/inpage-provider/issues/52
				Common.setWeb3ProviderForContracts(ethereum);
			} else {
				if (typeof window !== 'undefined') {
					console.log('No injected web3 provider detected; falling back to default.');
				}
				try {
					Common.setWeb3Provider(await Common.getWebSocketProvider(Web3param));
					Common.setWeb3ProviderForContracts(await Common.getWebSocketProvider(Web3param));
				} catch(err) {
					reject(err);
				}
			}
			/* Commenting out 10/19/20 as this appears to have been addressed in dependencies.
			if (typeof window !== 'undefined') {
				console.warn("Initializing web3. You may see warnings about deprecation of 'data' and 'close' in the next two lines due to https://github.com/MetaMask/metamask-extension/issues/9301.");
			}
			*/
			const web3ToReturn = new Web3/*param*/(Common.web3Provider);
			//globalThis.web3 = web3ToReturn;
			//This application is now using web3 version ',web3.version
			resolve(web3ToReturn);
		});
	},

	/**
	 * Aiming to deprecate this, though it's still used in migrations and some unconverted cache manager files.
	 * @param tableObj should be a TruffleContract instance, but the typings that ship with TruffleContract
	 * produces error "The initial value of Object.prototype.constructor is the standard built-in Object constructor."
	 * See also https://github.com/trufflesuite/truffle/issues/4825
	 */
	getTableName: function<
		K extends keyof Tables,
		T extends Tables[K] // TS200TableInstance = any,
	>(tableObj: T) : K | undefined {
		//@ts-ignore due to https://github.com/dethcrypto/TypeChain/issues/750
		if(typeof tableObj?.constructor?._json?.contractName === 'undefined') {
			//@ts-ignore due to https://github.com/dethcrypto/TypeChain/issues/750
			if(typeof tableObj?.constructor?._json?.contract_name === 'undefined') {
				return undefined;
			} else {
				//@ts-ignore due to https://github.com/dethcrypto/TypeChain/issues/750
				return tableObj.constructor._json.contract_name; //typically just 'Contract' at least for ts200l
			}
		} else {
			//@ts-ignore due to https://github.com/dethcrypto/TypeChain/issues/750
			return tableObj.constructor._json.contractName;
		}
		//There is also return tableObj.constructor._properties.contract_name.get();
		//but this throws an error in the Truffle code as of the version TS200l uses
		//in the browser as of 12/9/20.
	},

	/** Due to a bug in whatever undiscernable version of truffle-contract ts200l uses in the interface,
	* this function does not work and there is no way to get the contract name from the TruffleContract
	* object; the name needs to be passed as a parallel parameter.
	* Querying contractObj._json.contract_name as an alternative when this is undefined produces 'Contract'
	* instead of the useful name of the contract, due to TS200l's truffle-contract.js line 7650
	* @param contractObj : see comments on getTableName(), though it is the contract rather than the instance
	*/
	getContractName: function(contractObj: any) : string {
		return contractObj._json.contractName;
	},

	getTransactionDetail: function(
		web3object: Web3,
		txHash: string
	) {
		return new Promise(function(resolve, reject) {
			web3object.eth.getTransaction(txHash, function(error: unknown, result: Transaction) {
				if(error) {
					//Any desired logging should be done in the CALLING CONTEXT.
					//In at least one case, certain errors are silently handled there;
					//a log statement here can be overwhelming.
					reject(error);
				} else {
					//Got transaction details for transaction with hash '+txHash+':',result
					resolve(result);
				}
			});
		});
	},

	getCurrentBlockNumber: function(web3object: Web3) : Promise<number> {
		return new Promise(function(resolve, reject) {
			web3object.eth.getBlockNumber(function(error : Error, blockNum : number) {
				if(error) {
					reject(error);
				} else {
					resolve(blockNum);
				}
			});
		});
	},

	getBlockInfo: function(
		web3object: Web3,
		blockNumber: BlockNumber
	) : Promise<BlockTransactionString> {
		return new Promise(function(resolve, reject) {
			if (BN.isBN(blockNumber)) {
				blockNumber = blockNumber.toString();
			}
			web3object.eth.getBlock(blockNumber, function(error : Error, blockData : BlockTransactionString) {
				if(error) {
					reject(error);
				} else {
					resolve(blockData);
				}
			});
		});
	},

	makeBlockTimestampReadable: function(blockTimestamp: number | string) {
		if(typeof blockTimestamp === 'string') { //possible per web3 type-def of timestamp field in BlockHeader
			blockTimestamp = parseInt(blockTimestamp);
		}
		return '≈'+new Date(blockTimestamp*1000);
	},

	matchesIncludingNullHexCaseInsensitive: function(eventValue: any, dbValue: any) {
		return (
			(Common.isEmpty(eventValue) && Common.isEmpty(dbValue)) ||
			(eventValue == dbValue) ||
			(BN.isBN(eventValue) && eventValue.eq(new BN(dbValue))) ||
			(//Hex strings are stored lowercase in the DB; account for that when comparing.
				(typeof eventValue == 'string') && (typeof dbValue == 'string') &&
				((eventValue.startsWith('\\x')) && (dbValue.startsWith('\\x')) ||
				(eventValue.startsWith('0x')) && (dbValue.startsWith('0x'))) &&
				(eventValue.toLowerCase() == dbValue.toLowerCase())
			)
		);
	},

	/** @returns {String}
	*/
	hashBytesString: function(
		web3: typeof Web3,
		bytesString: string,
		solidityType = 'bytes'
	) {
		//web3.utils.sha3(whatToHash) is not as flexible for expressing that
		//whatToHash is an array of bytes.
		if(bytesString === '') {
			solidityType = 'string';
		}
		return web3.utils.soliditySha3({type: solidityType, value: bytesString});
	},

	hashString: function(web3: typeof Web3, stringToHash: string) {
		return Common.hashBytesString(web3, stringToHash, 'string');
	},

	/** @returns {Promise} resolving to accounts[0].
	*/
	selectAccount: function(web3: Web3, promptForConnectionIfNone = false) : Promise<string> {
		return new Promise(function(resolve, reject) {
			Common.getAccounts(web3, promptForConnectionIfNone).
			then(function(accounts: string[]) {
				if(accounts.length==0) {
					//Would be better to hide administrative functionality if no owner account found.
					console.log('When attempting to select an account, no accounts were found.'+
					'  If using metamask and trying to modify, are you signed in?');
					resolve('');
				} else {
					Common.userAccount = accounts[0]; //Would be better to improve on this by looking through accounts for privileged one
					web3.eth.defaultAccount = accounts[0];
					resolve(accounts[0]);
				}
			}).catch(function(error: unknown) {
				//E.g. user might have rejected access
				reject(error);
			});
		});
	},

	cutUnderscore: function(stringIn: string) {
		if(stringIn.charAt(0)=='_') {
			return stringIn.substring(1);
		} else {
			return stringIn;
		}
	},

	//Warning: Does not deduplicate BN values!
	//Currently, this is only used for a field named 'catchall' in file dump comparisons,
	//so it shouldn't actually be getting used in practice and this should not be a big problem.
	deduplicateArray: function(arrayIn: any[]) {
		return arrayIn.filter(function(element, index, array) {return array.indexOf(element) === index});
	},

	/** Sorts array in place, changing the param passed in,
	* but doesn't delete the elements from the array passed in.
	* By WBT. Seems likely to be more efficient than deduplicateArray().
	*/
	sortAndDeduplicateArray: function(arrayIn: any[]) {
		arrayIn.sort();
		return Common.deduplicateSortedArray(arrayIn);
	},

	deduplicateSortedArray: function(arrayIn: any[]) {
		return arrayIn.filter(
			function(element, index, array) {
				return (index === 0 || element != array[index-1]);
			}
		);
	},

	//See https://github.com/microsoft/TypeScript/issues/23132
	//for more on why the return type can't be specified as e.g.
	//: (T extends string ? string : A)[]
	splitByCommaIfNotAlreadyArray: function<
		A extends any,
		T extends (A[] | string)
	>(
		arrayOrStringIn: T
	) {
		if(Array.isArray(arrayOrStringIn)) {
			return arrayOrStringIn;
		} else if(arrayOrStringIn.length === 0) {
			return [];
		} else {
			return arrayOrStringIn.split(',');
		}
	},

	/** @returns {boolean} true if the input is a positive integer or string
	* representing a positive integer.
	* TRUE: 1, '1', '01'
	* FALSE: -1, '-1', 1x, '0'
	* ts-ignore may be used due to TypeScript issue #26592 because the function takes
	* advantage of JavaScript type coercion and the == instead of === on the first check;
	* comparing a string to a number results in a TypeScript error that the first boolean
	* == check will always return false. ts(2367) is the error code.
	*/
	isPositiveIntString: function(strIn?: string | number) {
		if(typeof strIn === 'undefined') {
			return false;
		} else if(typeof strIn === 'string') {
			// @ts-ignore due to https://github.com/microsoft/TypeScript/issues/26592
			return ((strIn == parseInt(strIn)) && (parseInt(strIn)>0));
		} else {
			return strIn > 0;
		}
	},

	//TS#33912 prevents use of something like makeBigNumberString: function<T extends object | number | string>(val: T) : (T extends null ? undefined : string) {
	makeBigNumberString: function(val: object | number | string) {
		if(typeof val === 'object' || typeof val === 'number') {
			//Numbers come back as a BigNumber
			//https://github.com/ethereum/wiki/wiki/JavaScript-API#a-note-on-big-numbers-in-web3js
			//https://github.com/MikeMcl/bignumber.js/
			//With Web3 1.x, this has been updated to https://github.com/indutny/bn.js/
			if(val === null) {
				//This can happen as the default value for bytes (e.g. the hash of a WebFile)
				//console.warn("Warning: Null value in Common.makeBigNumberString. If you're using MetaMask, You may need to change your network selector away and back, then refresh.");
				//implicit: return undefined;
			} else {
				return val.toString();
			}
		} else {
			return val;
		}
	},

	makeBigNumberMonthDate: function(
		val : object | string,
		useShort : boolean
	) {
		if(typeof val === 'object') {
			return DateUtils.formatDateMonthYear(
				new Date(Number(val.toString())*1000), useShort
			);
		} else {
			return val;
		}
	},

	/**
	 * @returns {number or BN} newMaxID, 0 by default but overriden by log.args member if applicable
	 */
	parseNewMaxID: function(transaction: TruffleContractTxResult) {
		var newMaxID = new BN(0);
		if(!transaction.logs || transaction.logs.length==0) {
			console.log('No logs. Transaction details: ', transaction);
		} else {
			//When using geth, it seems that the .type entry in logs is no longer defined.
			if((transaction.logs[0].type !== undefined) && (transaction.logs[0].type != 'mined')) {
				console.warn('Warning: Creation transaction not mined; may be overwriting content!');
			}
			Common.warnIfEventIsNotAsExpected(transaction);
			if(transaction.logs[0].args['newMaxID']) {
				if (transaction.logs[0].args['newMaxID'] instanceof BN) {
					newMaxID = transaction.logs[0].args['newMaxID'];
				} else {
					newMaxID = new BN(transaction.logs[0].args['newMaxID']);
				}
			} else {
				console.log('Found a mined Creation event but no newMaxID log argument. Transaction details: ',transaction);
			}
		}
		return newMaxID;
	},

	//Split off from parseNewMaxID to reduce cognitive complexity of that function
	warnIfEventIsNotAsExpected: function(transaction: TruffleContractTxResult) {
		if(transaction.logs[0].event === undefined) {
			console.error('Likely log parsing error: Event is not identified in parseNewMaxID.');
		} else if(transaction.logs[0].event != 'Creation') {
			console.warn('Warning: Pulling MaxID from '+transaction.logs[0].event+' event instead of Creation.');
		}
	},

	getAJAXPath: function(contractName : string) {
		return ('/contractJSON/'+contractName+'.json');
	},

	/**
	* @returns {Promise} resolving to JSON
	*/
	getJSONFromAJAX: function(pathName : string) {
		//Used to just use jQuery for this, but can't run that easily in the automated test suite.
		return new Promise(function(resolve, reject) {
			Common.fetchAJAX(pathName, 'GET', 'text')
			.then(function(data) {
				const dataAsString = (
					typeof data === 'string' ?
					data :
					//@ts-ignore: data should be string, but keeping defense in case fn is made more flexible in the future
					data.toString()
				);
				resolve(JSON.parse(dataAsString));
			}).catch(function(error: unknown) {
				reject(error);
			});
		});
	},

	/** @returns {String} of up to length
	* @param maxLength.
	* Requires global window.crypto object.
	*/
	generateRandomPassword: function(maxLength = 32) {
		let typedArray = new Uint8Array(maxLength*2);
		window.crypto.getRandomValues(typedArray);
		let newPassword = '';
		for (let i = 0; i < maxLength; i++) {
			const asciiIndex = Math.floor(typedArray[i]/2);
			if(
				(asciiIndex > 32) && //unprintables and space
				(asciiIndex != 34) && // "
				(asciiIndex != 39) && // '
				(asciiIndex != 44) && // ,
				(asciiIndex != 46) && // .
				(asciiIndex != 47) && // /
				(asciiIndex != 92) && // \
				(asciiIndex != 96) && // `
				(asciiIndex < 127) // DEL
			) {
				//fromCharCode interprets values as UTF-16;
				//sources differ on ASCII compatibility.
				newPassword += String.fromCharCode(asciiIndex);
			}
		}
		return newPassword;
	},

	/**
	* @returns {Promise} resolving to data, where the type depends on the request's `reponseType` property.
	* @param dataBody is documented at
	* https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/send
	* If it's a Document, it's serialized before sending.
	* It can also be an XMLHttpRequestBodyInit: a Blob, BufferSource, FormData, URLSearchParams, or USVString object.
	* The best way to send binary content (e.g. in file uploads) is by using an ArrayBufferView or Blob type.
	* However, contrary to all the available documentation, passing a File object
	* (which is a Blob) as dataBody leads to no dataBody actually being sent out in Chrome,
	* even when the File object is clearly seen just before send() is called.
	* Try using a FormData or (as common in this implementation, esp. w/Signature Auth) an ArrayBuffer object instead.
	* It defaults to null b/c that's the behavior if no param is given to send().
	*/
	fetchAJAX: function<RT extends keyof PossibleAJAXResponseTypeMap>(
		pathName: string,
		httpMethod : HTTPMethod = 'GET',
		responseType : RT, //https://stackoverflow.com/questions/58850830 looks impossible to specify default 'text' for inferred generic type param
		specifyResponseType : boolean = false, //should be true if providing any responseType rather than relying on default
		headersObject : HeadersObject = {},
		dataBody: RequestBodyType = null
	)  : Promise<PossibleAJAXResponseTypeMap[RT]> {
		const responseTypeForRequest : undefined | keyof XMLHttpRequestResponseTypeMap = (responseType === 'file' ? 'blob' : responseType);
		return new Promise<PossibleAJAXResponseTypeMap[RT]>(function(resolve, reject) {
			//console.log('Accessing '+httpMethod+' '+pathName+' via AJAX. Body: ',dataBody, 'responseType: ',responseType);
			//'Despite its name, XMLHttpRequest can be used to retrieve any type of data, not just XML.'
			//-https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
			let request = new XMLHttpRequest();
			//Would be better to listen for upload progress here: see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
			request.open(httpMethod, pathName, true); //3rd param: async
			//console.log('Opening XMLHttpRequest with pathName '+pathName+' produces ',request,' with responseURL of '+typeof request.responseURL,request.responseURL);
			if(specifyResponseType) {
				request.responseType = responseTypeForRequest;
			}
			for(let headerKey in headersObject) {
				request.setRequestHeader(headerKey, headersObject[headerKey]);
			}
			request.onload = Common.getAjaxOnloadFn<RT>(resolve, reject, pathName, responseType, httpMethod);
			request.onerror = function() {
				//Apparently no param to this callback with more information, per
				//https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequestEventTarget/onerror
				const errMessage = 'Unknown error with '+httpMethod+' call to '+pathName+'.';
				console.log(errMessage);
				reject({'message': errMessage, 'XMLHttpRequestObj': this});
			};
			//console.log('dataBody just before sending request is', dataBody)
			request.send(dataBody);
		});
	},

	/** Split out from fetchAJAX just to satisfy Sonar's cognitive complexity measure; use that.
	 *
	 */
	getAjaxOnloadFn: function<RT extends PossibleAJAXResponseTypeSpecifier>(
		resolve : (value: PossibleAJAXResponseTypeMap[RT] | PromiseLike<PossibleAJAXResponseTypeMap[RT]>) => void,
		reject : (reason: any) => void,
		pathName: string,
		responseType : RT,
		httpMethod : HTTPMethod = 'GET',
	) {
		return function(
			this: XMLHttpRequest, //NOT A FIRST PARAM! Erased during TS compilation. https://www.typescriptlang.org/docs/handbook/2/classes.html#this-parameters
		) {
				if (this.status === 202) {
					//Status: Accepted: The request has been accepted for processing, but the processing has not been completed.
					//The request might or might not eventually be acted upon, as it might be disallowed when processing actually takes place.
					reject({XMLHttpRequestObj: this});
				} else if (this.status >= 200 && this.status < 400) { // Success!
					//console.log('Fetching '+pathName+', recieved response with server code '+this.status);
					const lastModified = Common.parseDateFromLastModHeader(this.getResponseHeader('Last-Modified')); // null if not present
					let response = this.response;
					if(responseType === 'file') {
						let urlAfterRedirects = new URL(this.responseURL);
						response = new File(
							[this.response],
							Common.getFilenameFromPath(urlAfterRedirects.pathname),
							{
								type: this.response.type,
								lastModified: ((lastModified === null ? new Date() : lastModified).valueOf())
							}
						);
					}
					resolve(response);
				} else if(
					(this.status === 400) || //Bad Request
					(this.status === 403) || //Forbidden
					(this.status === 404) || //Not Found
					(this.status === 409) || //Conflict: Disallowed overwrite
					(this.status === 413) || //Payload Too Large
					(this.status === 500)	//Internal Server Error
				) {
					reject({XMLHttpRequestObj: this});
				} else {
					const errMessage = 'Error with '+httpMethod+' call to '+decodeURI(pathName)+
					': Server responded with HTTP status '+this.status;
					if(this.status != 401) {
						console.log(errMessage);
					}
					reject({'message': errMessage, 'XMLHttpRequestObj': this});
				}
			};
	},

	getFilenameFromPath: function(pathIn : string) {
		let pathLastSlash = pathIn.lastIndexOf('/');
		return (pathIn.substring(pathLastSlash));
	},

	/**
	* An alternative for getJSONFromAJAX() above, using JQuery
	* @returns {Promise} resolving to JSON
	*/
	getJSONJquery: function(pathName : string) {
		return new Promise(function(resolve, reject) {
			$.getJSON(
				pathName,
				//getJSON takes a callback with up to 3 params, data first.
				//https://api.jquery.com/jQuery.getJSON/
				function(data) {
					resolve(data);
				}
			);
		});
	},

	/**
	* @returns {Promise} resolving to array of initiated contracts
	* Like initContract, but contractNames should be an ARRAY of Strings
	*/
	initContracts: async function(
		contractNames: (string & keyof Tables)[],
		fsParam ?: fsType,
		TruffleContractConstructor ?: TruffleContractConstructorType,
		provider ?: Web3Provider,
		storeGlobally = true
	) : Promise<Partial<Tables>> {
		let results : Partial<Tables> = {};
		if(typeof TruffleContractConstructor === 'undefined' && typeof globalThis.TruffleContract !== 'undefined') {
			//In initContracts; TruffleContractConstructor is undefined. Using global value: '+globalThis.TruffleContract
			TruffleContractConstructor = globalThis.TruffleContract;
		}
		/* forEach strategy doesn't work with async
		//forEach object build-up by jcalz from https://tsplay.dev/WKRKMm via https://stackoverflow.com/q/73561966
		contractNames.forEach(async<TN extends TableName>(contractName: TN) => {
			results[contractName] = await Common.initContract(
				contractName,
				fsParam,
				provider,
				storeGlobally,
				TruffleContractConstructor
			);
		});
		Also, a regular for-of strategy doesn't work due to https://github.com/microsoft/TypeScript/issues/52420
		Map strategy used instead:
		*/
		await Promise.all(contractNames.map(async<TN extends TableName>(contractName: TN) => {
			return new Promise<void>(function(resolve, reject) {
				return Common.initContract(
					contractName,
					fsParam,
					provider,
					storeGlobally,
					TruffleContractConstructor
				).then(function(possibleInstance) {
					results[contractName] = possibleInstance;
					resolve();
				}).catch(function(err) {
					reject(err);
				});
			});
		}));

		/* Per https://tsplay.dev/WJ51lm the contractNames.forEach line can be replaced with
		async function initAndAssign<TN extends TableName>(contractName: TN) {
		//and then replace the }); line with
		}
		for(let contractName of contractNames) {
			initAndAssign(contractName);
		}
		*/
		return results;
	},

	/**
	* @returns {Promise} resolving to the intiated contract INSTANCE
	*/
	initContract: function<
		TN extends TableName
	>(
		contractName: TN,
		fsDef ?: fsType,
		provider ?: Web3Provider,
		storeGlobally = true,
		TruffleContractConstructor ?: TruffleContractConstructorType
	) : Promise<Tables[TN] | undefined> {
		return new Promise(async function(resolve, reject) {
			if(typeof Common.contracts[contractName]==='undefined' || !storeGlobally) {
				//only fetch JSON if contract not already initiated
				var jsonFilename = './base/build/contracts/'+contractName+'.json';
				if (typeof fsDef?.readFile !== 'undefined') {
					//console.log('Using fs to load '+jsonFilename);
					//The version of this at fileFns.readFile() is more promisified.
					fsDef.readFile(jsonFilename,
						//fs.readFile takes a callback with two params, error first.
						function(error: unknown, data: Buffer) {
							if(error) { //data === undefined
								return reject(error);
							} else {
								//Data after reading file '+jsonFilename+': ',data
								return Common.initContractFromJSON(
									contractName,
									JSON.parse(data.toString()),
									provider,
									storeGlobally,
									TruffleContractConstructor
								).then(function(table) {
									resolve(table);
								}).catch(function(err: unknown) {
									reject(err);
								});
							}
						}
					);
				} else {
					//Using AJAX to load '+contractName;
					return Common.getJSONFromAJAX(Common.getAJAXPath(contractName)) //getJSONJquery is an alternative to getJSONFromAJAX
					.then(function(json) {
						//This code could be made more robust by checking before the cast in the next line, but
						//https://github.com/trufflesuite/truffle/issues/4825 makes that a lot harder than it otherwise would be.
						return Common.initContractFromJSON(
							contractName,
							json as ContractObject,
							provider, storeGlobally,
							TruffleContractConstructor
						);
					}).then(function(table) {
						resolve(table);
					}).catch(function(error: unknown) {
						reject(error);
					});
				}
			} else {
				//Contract already initiated
				//console.log('INFO: In initContract, contract '+contractName+' already initiated.');
				resolve(Common.tables[contractName]);
			}
		});
	},

	/**
	 * @resolves to a contract INSTANCE
	 */
	initContractFromJSON: function<
		TN extends TableName
	>(
		contractName: TN,
		contractJSON: ContractObject,
		provider : Web3Provider | undefined = undefined,
		storeGlobally = true,
		TruffleContractConstructor : TruffleContractConstructorType | undefined = undefined,
	) : Promise<Tables[TN] | undefined> {
		let contractNameFromContractJSON = contractJSON.contractName;
		if(contractNameFromContractJSON !== contractName) {
			throw new Error('Contract name from contract JSON ' + contractNameFromContractJSON + ' did not match the expected ' + contractName);
		}
		if(!storeGlobally || typeof contractName === 'undefined' || (Common.contracts[contractName]===undefined)) { //only initiate if not already done so.
			if(typeof TruffleContractConstructor !== 'function') {
				console.error('TruffleContractConstructor passed to initContractFromJSON: ',TruffleContractConstructor);
				return Promise.reject('No valid TruffleContractConstructor passed to initContractFromJSON.');
			}
			let contract = TruffleContractConstructor(contractJSON);
			if(provider === undefined) {
				//Provider param is undefined for contract '+contractName+'; pulling from default Common.web3ProviderForContracts
				provider = Common.web3ProviderForContracts;
			//} else {
				//console.log('Provider param given for contract '+contractName+':',provider);
			}
			//console.log('Still in initContractFromJSON for contract '+contractName+'; provider is ',provider);
			//For truffle-contract 4.0.x (https://github.com/trufflesuite/truffle/issues/1942):
			//Not using setProvider produces 'INFO: Error when looking for deployed instance of QuestionResponse contract: Provider not set or invalid.'
			//Using setProvider produces 'MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 101 data listeners added. Use emitter.setMaxListeners() to increase limit'
			//console.log('Setting provider in contract, provider URL is ',Common.web3ProviderForContracts.url/*,' and connection is ',Common.web3ProviderForContracts.connection*/);
			contract.setProvider(provider);
			if(storeGlobally && typeof contractName !== 'undefined') {
				Common.contracts[contractName] = contract;
				//Contract has been set: ',Common.contracts[contractName]
			}
			return Common.getTableFromContract(contract, storeGlobally, contractName);
		} else {
			//Contract already initiated
			console.log('INFO: In initContractFromJSON, contract '+contractName+' already initiated.');
			return Promise.resolve(Common.tables[contractName]);
		}
	},

	/**
	* See comments on getContractName for why contractName is a parameter, esp. for TS200l.
	* @param truffleContract : see comments on getTableName(), though it is the contract rather than the instance
	* Resolves to an INSTANCE of a TruffleContract.
	*/
	getTableFromContract: async function<
		TN extends TableName
	>(
		truffleContract: Truffle.Contract<TS200TableInstance>,
		storeGlobally: boolean,
		contractName : TN
	) : Promise<Tables[TN] | undefined> {
		//console.log('Getting deployed address from contract ',truffleContract);
		//As seen by logging just before and after this next line, this call
		//to .deployed() sometimes takes quite a while (minutes).
		let fetchDeployedTimer = setTimeout(function() {
			console.warn('In getTableFromContract with '+contractName+' contract, timeout encountered waiting for '+contractName+'.deployed() to resolved. Are you connected to the right network? Does your build/contracts directory match your target environment?');
		}, 10*1000)
		try {
			const instance = await truffleContract.deployed() as Tables[TN]; //see comments on getTableName() re: type of 'instance
			clearTimeout(fetchDeployedTimer);
			if(storeGlobally) {
				Common.tables[contractName] = instance;
			}
			return instance;
		} catch(err: any) {
			//not using reject due to Promise.all and brittleness of
			//comparing the error message to hard-coded text.
			//When the error string changes, the result should just be
			//a console log instead of a break in functionality.
			if(err?.message?.endsWith(' has not been deployed to detected network (network/artifact mismatch)')) {
				console.log("Can't find contract location on network. Are you connected to the right network? If using this application locally, does your build/contracts directory match your target environment?");
				return;
			} else {
				console.log('INFO: Error when looking for deployed instance of '+
				contractName+' contract: ', err);
				return;
			}
		}
	},

	/**
	* Synchronous.
	* @param compoundIDprefix does NOT include the dash.
	*/
	getValueFromElementOrAncestorNonChain: function<
		TN extends TableName, //TODO: See if this can be restricted to FamilyTableName
		FN extends keyof FullRowDataMap[TN] = keyof FullRowDataMap[TN]
	>(
		tableData : TableDataFromName<TN>,
		fieldName : FN,
		compoundIDprefix : string,
		id: number | string,
		logger : PossiblyConsoleLogger = console
	): ValueFromElementOrAncestor<FullRowDataMap[TN][FN] | undefined> {
		return Common.getResultFromElementOrAncestorNonChain<TN, FullRowDataMap[TN][FN] | undefined>(
			tableData,
			function(rowData: RowDataMappedFromContractName<TN>) {return rowData[fieldName];},
			compoundIDprefix,
			id,
			logger
		);
	},

	/**
	* Synchronous.
	* @param compoundIDprefix does NOT include the dash.
	* idsTested does NOT include the present, unlike in the getValueFromElementOrAncestor not defined immediately above.
	* @param whatToRun should be a function(rowData)
	*/
	getResultFromElementOrAncestorNonChain: function<
		TN extends TableName, //TODO: See if this can be restricted to FamilyTableName
		V
	>(
		tableData : TableDataFromName<TN>,
		whatToRun : (arg0: RowDataMappedFromContractName<TN>) => V,
		compoundIDprefix : string,
		id : ChainNumber,
		logger : PossiblyConsoleLogger,
		idsTested : ChainNumber[] = []
	) : ValueFromElementOrAncestor<V> {
		let compoundID = Chainreader.makeCompoundID(compoundIDprefix, id);
		let rowData = tableData[compoundID];
		if(rowData === undefined) {
			logger.error('Could not find '+compoundID+' in tableData passed to getResultFromElementOrAncestorNonChain; this may not be the right function to use.');
			return({directObjectID: id, value: undefined});
		}
		let result = whatToRun(rowData);
		//Below: Casts on 'parent' should not be necessary due to 'in' check and 2nd conditional block, but apparently are in current TS
		if((!Common.isEmpty(result)) || !('parent' in rowData) || Common.isEmpty(rowData['parent' as keyof typeof rowData])){
			return({directObjectID: id, value: result})
		} else if (!('parent' in rowData) || typeof rowData?.['parent' as keyof typeof rowData] === 'undefined') {
			throw(new Error('Programming bug: reached conditional branch that exists only because isEmpty does not guard for TypeScript.'));
		} else if(idsTested.includes(rowData['parent' as keyof typeof rowData] as ChainNumber)) {
			logger.error('Cycle detected in graph of parents; parent ID '+rowData['parent' as keyof typeof rowData]+' already in set of '+idsTested.join(', '));
			return({directObjectID: id, value: result});
		} else {
			idsTested.push(id);
			return Common.getResultFromElementOrAncestorNonChain(tableData, whatToRun, compoundIDprefix, rowData['parent' as keyof typeof rowData] as ChainNumber, logger, idsTested);
		}
	},

	dropNullsFromArray: function<T>(arrayIn: (T | null)[]) : T[] {
		var retval = [];
		for(let arrayElement of arrayIn) {
			if(arrayElement !== null) {
				retval.push(arrayElement);
			}
		}
		return retval;
	},

	dropUndefinedsFromArray: function<T>(data: (T | undefined)[]): T[] {
		let results : T[] = []
		for(let possibleResultRow of data) {
			if(typeof possibleResultRow !== 'undefined') {
				results.push(possibleResultRow);
			}
		}
		return results;
	},

	//Should exclude NonReadFn types
	isViewFn: function(
		fnABI ?: AbiItem
	) : fnABI is NormalFnABI {
		return ((fnABI !== undefined) && (fnABI.type == 'function') && (fnABI.stateMutability == 'view'));
	},

	hasSingleNonTupleOutput: function(
		fnABI : NormalFnABI
	) {
		return (
			(fnABI.outputs.length == 1) && //Returns just one value
			(fnABI.outputs[0].type != 'tuple') //Can't read directly: https://github.com/ethereum/web3.js/issues/1241
		);
	},

	/**
	* @returns {boolean} indicator if a given function ABI looks like a field for a table row.
	* Used for determining if the function should be included in the fields list when reading a row.
	* Heuristic guess based on strong convention.
	* This should match the type definition for ReadFieldFn.
	*/
	isFieldFn: function(
		fnABI ?: AbiItem
	) : fnABI is NormalFnABI {
		//console.log('Checking if isFieldFn the abi ', fnABI);
		//See conditions also listed in Explorer.getValueFromReadFn.
		//Does not modify name of single output as in that function.
		return ((Common.isViewFn(fnABI)) && //Exclude<..., NonReadFnWithName>
		//Except disqualifying tuple outputs, the next three conditions are part of filtering to IDIndexedFn:
		(Common.hasSingleNonTupleOutput(fnABI)) &&
		(fnABI.inputs.length == 1) && // == 0 for table-wide functions like owner.
		(fnABI.inputs[0].type == 'uint32')); //takes an id (or something like it) as input
	},

	isTwoChainNumberParamFn: function(
		fnABI ?: AbiItem
	) : fnABI is NormalFnABI { //fnABI corresponds to a TwoChainNumReadFieldFn
		return ((Common.isViewFn(fnABI)) && //Exclude<..., NonReadFnWithName>
		(Common.hasSingleNonTupleOutput(fnABI)) &&
		(fnABI.inputs.length == 2) && // == 0 for table-wide functions like owner.
		(fnABI.inputs[0].type.startsWith('uint')) &&
		(fnABI.inputs[1].type.startsWith('uint')));
	},

	/** @returns {String} with date formatted as described at
	* https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
	* @param date is a Date object.
	* Note: this loses millisecond precision!
	* (Alternatives that keep milliseconds still have it fuzzed to prevent fingerprinting though.
	* See https://developer.mozilla.org/en-US/docs/Web/API/File/lastModified )
	*/
	formatDateForHTTPLastModHeader: function(date = new Date()) {
		let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		return dayNames[date.getUTCDay()] + ', ' +
		date.getUTCDate().toString().padStart(2, '0') + ' ' +
		DateUtils.getMonthName(date.getUTCMonth(), true) + ' ' +
		date.getUTCFullYear() + ' ' +
		date.getUTCHours().toString().padStart(2, '0')+ ':' +
		date.getUTCMinutes().toString().padStart(2, '0')+ ':' +
		date.getUTCSeconds().toString().padStart(2, '0')+ ' GMT';
	},

	/** The inverse of formatDateForHTTPLastModHeader().
	* @param {String} headerValue similar to 'Tue, 14 May 2019 19:33:52 GMT'
	*/
	parseDateFromLastModHeader: function(headerValue : null | string) {
		if(headerValue === null) {
			return null;
		}
		headerValue = headerValue.trim();
		let retval = new Date();
		retval.setUTCFullYear(parseInt(headerValue.substring(12, 16)));
		const monthIndex = Common.getMonthIndexFromName(headerValue.substring(8, 11));
		if(typeof monthIndex === 'undefined') {
			throw(new Error('Invalid month name in last modified header.'));
		}
		retval.setUTCMonth(monthIndex);
		retval.setUTCDate(parseInt(headerValue.substring(5, 7)));
		retval.setUTCHours(parseInt(headerValue.substring(17, 19)));
		retval.setUTCMinutes(parseInt(headerValue.substring(20, 22)))
		retval.setUTCSeconds(parseInt(headerValue.substring(23, 25)))
		return retval;
	},

	/** @param {String} name can be e.g. 'Jan' or 'January'
	* If the first three letters match the first three letters of an English
	* month name,
	* @returns {int} the index (Jan = 0); else
	* @returns undefined
	*/
	getMonthIndexFromName: function(name : string) {
		if(name.startsWith('Jan')) {
			return 0;
		} else if(name.startsWith('Feb')) {
			return 1;
		} else if(name.startsWith('Mar')) {
			return 2;
		} else if(name.startsWith('Apr')) {
			return 3;
		} else if(name.startsWith('May')) {
			return 4;
		} else if(name.startsWith('Jun')) {
			return 5;
		} else if(name.startsWith('Jul')) {
			return 6;
		} else if(name.startsWith('Aug')) {
			return 7;
		} else if(name.startsWith('Sep')) {
			return 8;
		} else if(name.startsWith('Oct')) {
			return 9;
		} else if(name.startsWith('Nov')) {
			return 10;
		} else if(name.startsWith('Dec')) {
			return 11;
		}
	},

	/**
	* Returns string in format mm/dd/yyyy or mm/yyyy depending on includeDay param.
	*/
	formatDateSlashedNumbers: function(
		date : Date,
		includeDay = true,
		localTime = false
	) {
		//Note: This is for mm/dd/yyyy, the default format for vanillajs-datepicker.
		//Eventually should add support other formats as part of i18n
		let year = date.getUTCFullYear();
		let monthIndex = date.getUTCMonth();
		let dateInMonth = date.getUTCDate();
		if(localTime) {
			year = date.getFullYear();
			monthIndex = date.getMonth();
			dateInMonth = date.getDate();
		}
		let dateString =(monthIndex+1).toString().padStart(2,'0');
		if(includeDay) {
			dateString += '/'+dateInMonth.toString().padStart(2,'0');
		}
		dateString += '/'+year.toString().padStart(4,'0');
		return dateString;
	},

	/**
	* Returns string in format yyyy-mm[-dd] with the last part depending on includeDay param.
	*/
	formatDateForFileNaming: function(
		date : Date,
		includeDay = true,
		localTime = false
	) {
		let year = date.getUTCFullYear();
		let monthIndex = date.getUTCMonth();
		let dateInMonth = date.getUTCDate();
		if(localTime) {
			year = date.getFullYear();
			monthIndex = date.getMonth();
			dateInMonth = date.getDate();
		}
		let dateString = year.toString().padStart(4,'0');
		dateString += '-'+(monthIndex+1).toString().padStart(2,'0');
		if(includeDay) {
			dateString += '-'+dateInMonth.toString().padStart(2,'0');
		}
		return dateString;
	},

	formatDateForFormalWriting: function(date : Date, includeDay = true) {
		let dateString = DateUtils.getMonthName(date.getUTCMonth(), false)+' ';
		if(includeDay) {
			dateString += date.getUTCDate().toString().padStart(2,'0')+', ';
		}
		dateString += date.getUTCFullYear().toString().padStart(4,'0');
		return dateString;
	},

	wait: function(ms : number) {
		return new Promise(function(resolve, reject) {
			setTimeout(resolve, ms);
		});
	},

	/**
	* @returns {Boolean} true if the passed parameters are (1) equal, or (2) both empty.
	*/
	equalOrBothEmpty: function(valA : any, valB : any) {
		return ((valA == valB)||(Common.isEmpty(valA) && Common.isEmpty(valB)));
	},

	//Note: Common.isEmptyForSolType is more domain-specific.
	isEmpty: function(
		data : any
	) : data is EmptyType {
		//Regex intentionally matches '0', '0x', '0x00',
		//'0x0000000000000000000000000000000000000000',
		//'0x0000000000000000000000000000000000000000000000000000000000000000', etc.
		let emptyHexRegex = RegExp('^0x?0*$');
		return (
			(data===undefined) ||
			(data===null) ||
			(data===false) ||
			(data===0) ||
			(data==='') ||
			(Array.isArray(data) && data.length === 0) ||
			((data.isZero != undefined) && (data.isZero())) ||
			(data instanceof Set && data.size === 0) ||
			//Use of getOwnProperty names in the next line includes non-enumerable string-keyed own properties,
			//covering more than just `keys`.  Either set will return empty for e.g. an HTML element,
			//which motivates going up the prototype chain.
			(data instanceof Date && data.valueOf() === 0) || //note that invalid dates like new Date('random_string') are instanceof Date.
			(!(data instanceof Date) && typeof data=== 'object' && Object.getOwnPropertyNames(data).length === 0 && Common.isEmpty(Object.getPrototypeOf(data))) ||
			(!(data instanceof Date) && emptyHexRegex.test(data)) || //Regex testing calls Date.prototype.toString() with a wrong 'this', throwing an error.
			//@ts-ignore: Next condition will generally return 'false'
			//since JavaScript compares objects by reference, not value,
			//but that extra false is not especially harmful and might help
			//if there's some odd compiler bug where 'generally' != 'always'.
			(data==={})
		);
	},

	isEmptyForSolType: function(
		type : SolidityType,
		value : FieldValue
	) {
		if(typeof value === 'undefined') {
			return false;
		} else if(type.toLowerCase().startsWith('uint')) {
			return (value == 0);
		} else if (type.toLowerCase().startsWith('bytes')) {
			var byteCount = (value as string).length-2;
			var comparisonString = '0x'+('0').repeat(byteCount);
			return (value == comparisonString);
		} else if (type=='address') {
			return (value == '0x0000000000000000000000000000000000000000');
		} else {
			return false; //default
		}
	},

	/** Synchronous.
	* @returns {Object} with two keys: newArray,
	* and boolean wasFound which is true iff the itemToRemove was found in the array.
	*/
	copyWithoutElement: function<T> (
		oldArray : T[],
		itemToRemove : T
	) {
		return Common.replaceElement(oldArray, itemToRemove); //replaces with undefined
	},

	/** Synchronous.
	* @returns {Object} with two keys: newArray,
	* and boolean wasFound which is true iff the itemToRemove was found in the array.
	* When itemToReplaceWith === undefined, it doesn't do a replacement, just a removal.
	* You can use 'null' if you want to insert an empty value.
	*/
	replaceElement: function<T> (
		oldArray : T[],
		itemToRemove : T,
		itemToReplaceWith?: T,
		firstOnly = false
	) : {newArray : T[], wasFound : boolean} {
		let retval : {newArray : T[], wasFound : boolean} = {
			newArray : [],
			wasFound : false,
		};
		for(let index in oldArray) {
			if((oldArray[index] == itemToRemove) && (!retval.wasFound || !firstOnly)) {
				retval.wasFound = true;
				if(itemToReplaceWith !== undefined) {
					retval.newArray.push(itemToReplaceWith);
				}
			} else {
				retval.newArray.push(oldArray[index]);
			}
		}
		return retval;
	},

	hasCloneFn: function<T>(
		obj: StringIndexableObject
	) : obj is ObjWithCloneFn<T> {
		return ('clone' in obj && typeof obj.clone === 'function');
	},

	//Reference to check updates on state-of-the-art for this:
	//https://stackoverflow.com/questions/122102/
	//https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
	//though none of those appear to handle BN cloning.
	//if(!undefined) return JSON.parse(JSON.stringify(obj)); is LOSSY,
	//and converts BN objects to hex strings, which causes problems.
	//This implementation may be buggy, as seen in replicating an MCR with two identical arrays having different keys.
	deepCopy: function<T>(
		obj : T,
		copiedAncestors : any[] = []
	) : T {
		if(typeof obj !== 'object' || obj === null) { //includes obj === 'undefined'
			return obj;
		} else if (Common.hasCloneFn<T>(obj)) {
			return obj.clone();
		} else if (BN.isBN(obj)) {
			return (new BN(obj)) as T;
		} else {
			copiedAncestors.push(obj);
			let retval : T = (Array.isArray(obj) ? [] : {}) as T;
			for(let key in obj) {
				if(!Common.containsStrictEqual(copiedAncestors, obj[key])) {
					retval[key] = Common.deepCopy(obj[key], copiedAncestors);
				} else {
					console.error('During deepCopy, circularity detected in object at '+key+' key.');
					retval[key] = JSON.parse(JSON.stringify(obj[key]));
				}
			}
			return retval;
		}
	},

	//returns true iff searchVal === array[x] for x>=0.
	containsStrictEqual: function<T>(
		array : T[],
		searchVal : T
	) {
		for(let key in array) {
			if(array[key] === searchVal) {
				return true;
			}
		}
		return false;
	},

	errorIndicatesNodeOverwhelm: function(error : any) {
		//NOTE: Brittle as it depends on exact string.
		return (
			error?.message !== undefined &&
			error.message.toLowerCase().startsWith('could not connect to your ethereum client.')
		);
	},

	highCheckCountFor: function(txHash : string) {
		return ((Common.checkCount[txHash] != null) &&
		(Common.checkCount[txHash] >= Common.logAfterCheckCount));
	},

	getTransactionReceiptAsync: function(
		txHash: string,
		web3: Web3
	) : Promise<TransactionReceipt> {
		//web3... function is synchronous (no valid .then())
		//unless passing in callback, so passing in callback
		//to get asynchronous behavior.
		return new Promise(function(resolve, reject) {
			web3.eth.getTransactionReceipt(
				txHash,
				function(error : Error, data : TransactionReceipt) {
					if(error) {
						if(Common.errorIndicatesNodeOverwhelm(error)) {
							console.log('Error in getTransactionReceipt, apparently due to Ethereum node unreachability, to be caught in pollForResponse.');
						} else {
							console.log('Error in getTransactionReceipt:',error);
						}
						reject(error);
					} else {
						resolve(data);
					}
				}
			);
		});
	},

	/**
	* @returns {Promise} resolved with receipt (logs not parsed) on transaction success.
	* Rejects on transaction failure.
	* @param overwhelmRetriesLeft should be undefined for non-recursive calls.
	*/
	pollForResponse: function(
		txHash: string,
		web3: Web3,
		_overwhelmRetriesLeft: number | undefined = undefined,
	) {
		return new Promise(function(resolve, reject) {
			const overwhelmRetriesLeft = (typeof _overwhelmRetriesLeft === 'undefined') ? Common.nodeOverwhelmRetries : _overwhelmRetriesLeft;
			Common.getTransactionReceiptAsync(txHash, web3)
			.then(function(receipt: TransactionReceipt) {
				var notMined = false;
				if(receipt === null) {
					notMined = true;
					Common.handleNullReceipt(txHash);
				} else if(!receipt.status) {
					//No need to set notMined = true here because it's rejected before interpreting that variable
					let err = {message: 'Non-null receipt with status != 1, interpreted as failure.', receipt: receipt};
					console.error(err);
					return reject(err);
				} else {
					//Got receipt with status indicating successful transaction on '+functionName+':',receipt
					//Logs for successful call to '+functionName+':',receipt.logs
					Common.logMessageIfHighCheckCount('Got receipt indicating completion of tx ', txHash);
					delete Common.checkCount[txHash];
					resolve(receipt);
				}
				if(notMined) {
					Common.logMessageIfHighCheckCount('Waiting for mining of tx ', txHash);
					setTimeout(function() {
						Common.logMessageIfHighCheckCount('Re-checking mining status after wait, for tx ', txHash);
						return resolve(Common.pollForResponse(txHash, web3, overwhelmRetriesLeft));
					}, 400);
				}
			}).catch(function(err) {
				if(Common.errorIndicatesNodeOverwhelm(err)) {
					//This is handled separately here and in queueTransaction because if connection
					//to the server fails at the polling stage, the blockchain transaction should generally not be reissued.
					if(overwhelmRetriesLeft>0) {
						console.log('In pollForResponse for '+txHash+', it appears your Ethereum client is not available. Will retry up to '+overwhelmRetriesLeft+' times after a wait of '+Common.secondsWaitBetweenNodeOverwhelmRetries+' seconds each time.');
						setTimeout(function() {
							console.log('Re-polling for '+txHash+' after node overwhelm wait.');
							return resolve(Common.pollForResponse(txHash, web3, overwhelmRetriesLeft-1));
						}, Common.secondsWaitBetweenNodeOverwhelmRetries*1000);
					} else {
						const message = 'It appears your Ethereum client is not available when polling for '+txHash+', and no retries remain. Terminating now.';
						console.log(message);
						reject(message);
					}
				} else {
					console.log(err.message);
					reject(err);
				}
			});
		});
	},

	/**
	* Split off from pollForResponse just to reduce Sonar's cognitive complexity.
	*/
	logMessageIfHighCheckCount: function (
		message: string,
		txHash : string
	) {
		//Conditional logs only in rare circumstances where you've already reported you've checked several times.
		if(Common.highCheckCountFor(txHash)) {
			console.log(message + txHash+'.');
		}
	},

	/**
	 * Split off from pollForResponse just to reduce Sonar's cognitive complexity.
	 */
	handleNullReceipt: function (txHash : string) {
		if(typeof Common.checkCount[txHash] === 'undefined') {
			Common.checkCount[txHash] = 1;
		} else {
			Common.checkCount[txHash]++;
			if(Common.highCheckCountFor(txHash)) {
				console.log('Null receipt for transaction '+txHash+' after check #'+Common.checkCount[txHash]);
			}
		}
	},

	//-------------------BEGIN what was formerly logParser.js-----------------------
	//Until early Aug. 2020, that imported https://github.com/ethereum/web3.js/blob/v0.20.6/lib/web3/event.js
	//was renamed to 'events.js' 1/22/16 for 1.0.0-beta8 in https://github.com/ethereum/web3.js/commit/093f2fc1610c2524371b11f40038c51767ecc9a4#diff-e546d1b4f6c52978512e94d58a02ec57
	//then removed 1/26/17 still for 1.0.0-beta8 in https://github.com/ethereum/web3.js/commit/bbfefb091c5367ac155af107c73932fa496da884#diff-e546d1b4f6c52978512e94d58a02ec57

	parseLogsWithMerge: function(
		receipt : TransactionReceipt,
		web3: Web3,
		truffleContractInstances: Truffle.ContractInstance[]
	) {
		return new Promise(function(resolve, reject) {
			Common.parseLogs(
				receipt,
				web3,
				truffleContractInstances
			).then(function(parsedLogs) {
				resolve(Common.mergeLogsWithReceipt(receipt, parsedLogs));
			}).catch(function(err: unknown) {
				reject(err);
			});
		});
	},

	parseLogs: function(
		receipt: TransactionReceipt,
		web3: Web3,
		truffleContractInstances: Truffle.ContractInstance[]
	) : Promise<(Web3ReceiptLog | ParsedLog)[]>{
		var promises = [];
		for(let log of receipt.logs) {
			promises.push(Common.parseLog(log, web3, truffleContractInstances));
		}
		return Promise.all(promises);
	},

	parseLog: function(
		log: Web3ReceiptLog,
		web3: Web3,
		truffleContractInstances: Truffle.ContractInstance[]
	) : Promise<Web3ReceiptLog | ParsedLog> {
		return new Promise(function(resolve, reject) {
			/** Loops for the topic in each of the truffleContractInstances in order, and
			* returns the first match it finds. If no match is found, returns null.
			*/
			for (let contract of truffleContractInstances) {
				//Truffle does it at https://github.com/trufflesuite/truffle-contract/blob/dedc945c2beb1df56b6f501e00f16c081e6cd0e1/contract.js#L44
				//which is based on https://github.com/ethereum/web3.js/blob/cd1cfd9db6cacb494884a1824f8562c6440f85df/lib/web3/event.js#L133
				//This calls the latter code.
				let logABI = Common.findLogByTopicInContractInstance(log.topics[0], contract);
				if(logABI != null) {
					log.topics.shift(); //until https://github.com/ethereum/web3.js/pull/3692 or successor is merged
					let parsed = {
						event: logABI.name,
						args: web3.eth.abi.decodeLog(logABI.inputs, log.data, log.topics),
					};
					return resolve(parsed);
				}
			}
			console.log('Could not find event topic ',log.topics[0],' in truffleContractInstances. truffleContractInstances appears to be incomplete, not covering an event triggered by the transaction.');
			resolve(log);
		});
	},

	/** @returns {Promise} resolving to receipt with logs merged in.
	* Modifies receipt object passed as param, and resolves to modified object
	*/
	mergeLogsWithReceipt: function(
		receipt: TransactionReceipt,
		parsedLogs: (Web3ReceiptLog | ParsedLog)[]
	) {
		return new Promise(function(resolve, reject) {
			if(receipt.logs.length != parsedLogs.length) {
				reject('Likely programming bug in calling mergeLogsWithReceipt: parsed logs is of different length than unparsed logs.')
			} else {
				for(let logIndex = 0; logIndex<parsedLogs.length; logIndex++) {
					Object.assign(receipt.logs[logIndex], parsedLogs[logIndex]);
				}
				resolve(receipt);
			}
		});
	},

	/**
	* @returns logABI or null
	* @param truffleContractInstance : see comments on getTableName()
	*/
	findLogByTopicInContractInstance: function(
		topic: string,
		truffleContractInstance: any
	) : EventABI{
		return truffleContractInstance.constructor.events[topic];
	},

	//-------------------END what was formerly logParser.js-------------------------

	setCheckedStatus: function(
		checkbox: HTMLInputElement,
		desiredValue: boolean
	) {
		if(desiredValue) {
			checkbox.setAttribute('checked', 'true');
		} else {
			checkbox.removeAttribute('checked');
		}
	},

	insertIntoSequence: function(
		list: JQuery<HTMLElement>,
		newEntry: JQuery<HTMLElement>,
		seq1Num: number | string | BN,
		seq2Num: number | string | BN
	) {
		//2-level insertion sort: keeps display up-to-date as new data comes in.
		newEntry = newEntry.find('.sortUnit');
		if(BN.isBN(seq1Num)) {
			seq1Num = seq1Num.toString();
		}
		if(BN.isBN(seq2Num)) {
			seq2Num = seq2Num.toString();
		}
		//console.log('Inserting new sequence number '+seq1Num+' into '+list.attr('id')+' : ');
		//Inserts an element before the first element with a larger seqNum,
		//or at the end of the list if it does not encounter an element with a
		//larger seqNum.
		//That means a new element will be listed last among peers with equal seqNum,
		//which helps provide stability when processing in order.
		newEntry.attr('data-seq1Num', seq1Num);
		newEntry.attr('data-seq2Num', seq2Num);
		const listChildren = list.children();
		const sortOrderAttributeValue = list.attr('data-sortOrder');
		//This next line sets an interface rule that should probably be more clearly documented:
		var sortAscending = !((typeof sortOrderAttributeValue !== 'undefined') &&
			(sortOrderAttributeValue.toLowerCase().startsWith('desc')));
		let added = Common.addBeforeEndOfListIfAppropriate(
			listChildren,
			sortAscending,
			seq1Num,
			seq2Num,
			newEntry
		);
		if(!added) {
			list.append(newEntry);
		}
	},

	//Split off from insertIntoSequence to reduce cognitive complexity of that fn
	addBeforeEndOfListIfAppropriate (
		listChildren: JQuery<HTMLElement>,
		sortAscending: boolean,
		seq1Num: number | string,
		seq2Num: number | string,
		newEntry: JQuery<HTMLElement>
	) : boolean {
		let added = false;
		for(let element of listChildren) {
			let seq1NumFromElement = Common.getHTMLFieldOrEmptyMatchingTypeOf2ndParam(element, 'data-seq1Num', seq1Num);
			let seq2NumFromElement = Common.getHTMLFieldOrEmptyMatchingTypeOf2ndParam(element, 'data-seq2Num', seq2Num);
			//Iteration takes advantage of the fact that the list of existing elements is maintained sorted
			//Could use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort with comparator
			//TODO: Verify/improve comparison accuracy when BNs are passed and/or previously stored.
			if (Common.insertBeforeThisElement(
				sortAscending,
				seq1NumFromElement,
				seq1Num,
				element,
				seq2NumFromElement,
				seq2Num,
			)) {
				if(!added) {
					newEntry.insertBefore(element);
					added = true;
					break;
				}
			}
		}
		return added;
	},

	//Split off from addBeforeEndOfListIfAppropriate to DRY & reduce cognitive complexity of that fn
	getHTMLFieldOrEmptyMatchingTypeOf2ndParam(
		element: HTMLElement,
		attrName: string,
		paramToMatchTypeOf: string | number,
	) {
		let stringOrUndefinedFromHTML: string | number | undefined = $(element).attr(attrName);
		if(typeof stringOrUndefinedFromHTML === 'undefined') {
			stringOrUndefinedFromHTML = typeof paramToMatchTypeOf === 'string' ? '' : 0;
		}
		return stringOrUndefinedFromHTML;
	},

	//Split off from addBeforeEndOfListIfAppropriate to reduce cognitive complexity of that fn
	insertBeforeThisElement(
		sortAscending: boolean,
		seq1NumFromElement: string | number,
		seq1Num: string | number,
		element: HTMLElement,
		seq2NumFromElement: string | number,
		seq2Num: string | number,
	) : boolean {
		return ((sortAscending && ((seq1NumFromElement>seq1Num) ||
		(($(element).attr('data-seq1Num')==seq1Num) && (seq2NumFromElement>seq2Num)))) ||
		(!sortAscending && ((seq1NumFromElement<seq1Num) ||
		(($(element).attr('data-seq1Num')==seq1Num) && (seq2NumFromElement<seq2Num)))));
	},

	findFnABIByName: function(
		contractABItoLookIn: ABI,
		type: AbiMemberType,
		targetName: string
	) { //synchronous
		for (let fnABI of contractABItoLookIn) {
			if(
				('name' in fnABI) &&
				(fnABI?.name == targetName) &&
				(fnABI?.type == type)
			) {
				return fnABI;
			}
		}
		return undefined;
	},

	isKeyOf : function<
		T extends {[index: string] : any;}
	>(
		stringIndexedObject: T,
		possibleIndex: string
	 ) : possibleIndex is (string & keyof T) {
		return (possibleIndex in stringIndexedObject);
	},

};
