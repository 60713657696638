import type { DisplayableError } from '../../global.js';
export const AppUI = {
	loginSuccess: function () {
		sessionStorage.setItem('isEnabled', 'true');
	},
	loginUserRejection: function (e: DisplayableError) {
		sessionStorage.setItem('isEnabled', 'false');
		AppUI.displayErrorMessages(['Metamask account access denied. To use this interface, refresh the page and click Next, then Connect, on the "Connect With MetaMask" popup.']);
		e.alreadyShown = true;
	},

	clearServerStatusMessages: function() {
		$('#metaMaskMsg').text('');
		$('#successMsg').text('');
		$('#metaMaskMsg').addClass('hidden').removeClass('show');
		$('#successMsg').addClass('hidden').removeClass('show');
		if(typeof globalThis?.isMetaMaskMsgShown !== 'undefined') {
			globalThis.isMetaMaskMsgShown = false;
		}
	},

	displaySuccessMessages: function(messageLines: string[]) {
		let successElement = document.getElementById('successMsg');
		if(successElement === null) {
			console.error('Could not find successMsg element to display success messages', messageLines);
			return;
		}
		for (let line of messageLines) {
			successElement.innerHTML += line + '<br>';
		}
		successElement.classList.add('show');
		successElement.classList.remove('hidden');
	},

	displayErrorMessages: function(messageLines: string[]) {
		const errorDisplayElement = document.getElementById('metaMaskMsg');
		if(errorDisplayElement === null) {
			console.error('Could not find element for displaying error messages', messageLines);
			return;
		} else {
			console.error(messageLines);
		}
		for (let line of messageLines) {
			errorDisplayElement.innerHTML += line + '<br>';
		}
		errorDisplayElement.classList.add('show');
		errorDisplayElement.classList.remove('hidden');
		AppUI.autoScrollToTopOfThePage();
	},

	/**
	* auto scroll to top of the page
	* @param element - jquery object having html and body element
	*/
	autoScrollToTopOfThePage: function(verticalPixelsToLeaveHiddenAbove = 0) {
		$('html, body').animate({ scrollTop: verticalPixelsToLeaveHiddenAbove }, 'slow');
	},

	//Was exactly duplicated in searchViewer.js and certViewer.ejs
	showLoginButtonIfWeb3ProviderIsMetaMask: function() {
		//Next line used to include `&& web3.version.network !="loading"` which
		//produces ethereum.send() deprecation warning w/web3<1 & doesn't seem
		//to be or have ever been a valid return value.
		//Even when logged out of MetaMask, web3.version.network seems to be the
		//network ID, never 'loading'.
		//In https://github.com/ethereum/web3.js/issues/2378 a call to net_version
		//was changed to eth_chainId (released in web3 1.2.2)
		if (typeof ethereum !== 'undefined' && typeof ethereum.isMetaMask && ethereum.isMetaMask) {
			$('#loginButton').show();
		} else {
			$('#loginButton').hide();
		}
	},

	callAjaxForLoadingError: function() {
		console.log('Likely error when trying to load '+window.location.href+'. If you see no other errors above and are trying to use admin functionality, try disabling & re-enabling MetaMask, then refreshing the page.');
		$.ajax({
			type: 'POST',
			url: '/loadingDivErrorLogging',
			data: {'data': window.location.href},
			success: function (data) {
				console.log(data);
			},
			error: function (request, status, error) {
				console.log('Error when trying to log loading-error: ',request, status, error);
			}
		});
	},

	setTimeOutForLoadingDiv: function(isTimeOutCheckStart: boolean) {
		var setTimeIntervalForLoadingDiv: NodeJS.Timeout | undefined = undefined;
		var endtimeOut = 5;
		var startTimeOut = 0;
		const loadingDiv = document.getElementById("loadingDiv");
		if(isTimeOutCheckStart) {
			AppUI.showLoadingOverlay();
			setTimeIntervalForLoadingDiv = setInterval(function() {
				if(endtimeOut>=startTimeOut) {
					if(loadingDiv !== null) {
						if(loadingDiv.style.display == 'block') {
							startTimeOut = startTimeOut+1;
						} else {
							if(typeof setTimeIntervalForLoadingDiv !== 'undefined') {
								clearInterval(setTimeIntervalForLoadingDiv);
							}
							startTimeOut = 0;
						}
					}
				} else {
					AppUI.handleTooLongLoading();
					if(typeof setTimeIntervalForLoadingDiv !== 'undefined') {
						clearInterval(setTimeIntervalForLoadingDiv);
					}
					startTimeOut = 0;
				}
			},5000);
			return;
		}
		clearInterval(setTimeIntervalForLoadingDiv);
		if(loadingDiv !== null) {
			loadingDiv.style.display = "none";
		}
		startTimeOut = 0;
	},

	/** Split out from setTimeOutForLoadingDiv just for cognitive complexity purposes; call that instead of this one.
	 */
	showLoadingOverlay: function() {
		const loadingDiv = document.getElementById('loadingDiv');
		if(loadingDiv === null) {
			console.error('Could not find loading overlay to display when called to show it.');
			return;
		}
		if(loadingDiv.style.display!='block'
		&& typeof loadingDiv.style.display != 'undefined') {
			loadingDiv.style.display = 'block';
		}
	},

	handleTooLongLoading: function() {
		const loadingDiv = document.getElementById('loadingDiv');
		if(loadingDiv !== null && typeof loadingDiv.style.display != 'undefined') {
			if(loadingDiv.style.display == 'block') {
				AppUI.callAjaxForLoadingError();
				alert('Something went wrong. Please refresh the page.');
			}
			loadingDiv.style.display = 'none';
		}
	},

	/**
	* From CSVAnonymizer.ui
	* @returns null if none found; else the first that's found.
	* Logs if !== 1 result.
	*/
	getFirstByClassInGroup: function(fileGroup: Element, soughtClass: string) {
		const classMembers = fileGroup.getElementsByClassName(soughtClass);
		if(classMembers.length !== 1) {
			console.error('Searching for .'+soughtClass+' in ',fileGroup,' returned != 1 result:',classMembers);
			if(classMembers.length === 0) {
				return null;
			}
		}
		return classMembers[0];
	},

	printpage: function() {
		var printButton = document.getElementById("printpagebutton");
		if(printButton !== null) {
			printButton.style.visibility = 'hidden';
		}
		window.print();
		if(printButton !== null) {
			printButton.style.visibility = 'visible';
		}
	},
}
