import { AppUI } from './appUI.js';
export const HeaderFns = {
	loadIEBanner : function() {
		//TODO: Port to non-TS200 projects as part of code-base merge
		//Based on banners.js in INeedAShot.org
		var browser = "Not IE";
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older, return version number
			browser = ('IE ' + parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10));
		}
		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11, return version number
			var rv = ua.indexOf('rv:');
			browser = ('IE ' + parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10));
		}
		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			//Edge (IE 12+), return version number
			browser = ('IE ' + parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10));
		}
		// User uses other browser
		if (browser != 'Not IE') {
			var banners = document.getElementById('banners');
			var ie11banner = document.createElement('div');
			ie11banner.setAttribute('id', 'ie11banner');
			ie11banner.innerHTML =
			'<h2 class="iebanner"><strong>Unsupported Browser!</strong> This website will not function in this browser. <br>It only supports the recent versions of major browsers like Firefox and Chrome.'+
			'</h2>';
			if (banners !== null) {
				banners.appendChild(ie11banner);
			}
			AppUI.setTimeOutForLoadingDiv(false);
			return ie11banner;
		}
	},

	showIndexMaintenanceBanner : function() {
		const banners = document.getElementById('banners');
		const indexMaintenanceBanner = document.createElement('div');
		indexMaintenanceBanner.setAttribute('id', 'indexMaintenanceBanner');
		indexMaintenanceBanner.innerHTML =
		'<h2 class="iebanner">The information on this website is temporarily incomplete due to site maintenance. '+
		'<br>Please check back again in a few hours and if you still get this message, <a href="mailto:TS200@sae-itc.org">let us know</a>.'+
		'</h2>';
		if(banners !== null) {
			banners.appendChild(indexMaintenanceBanner);
		}
		return indexMaintenanceBanner;
	},
}
