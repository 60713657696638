import { AppUI } from '../appUI.js';
import { HeaderFns } from '../headerFns.js';
import { ScriptLoader } from '../scriptLoader.js';
import { SearchViewer } from '../searchViewer.js';
window.SearchViewer = SearchViewer; //referenced in HTML
ScriptLoader.runTasksImmediatelyOnPageLoad();

window.onload = function() {
	$("#headerBar").load("/header.html",function() {
		if(globalThis.showIndexMaintenanceBanner) {
			//relies on header to have previously been loaded
			//Call put here in case that fn is moved to appUI later on.
			HeaderFns.showIndexMaintenanceBanner();
		}
		AppUI.setTimeOutForLoadingDiv(true);
		SearchViewer.runOnDomReady();
		ScriptLoader.loadTruffleContract().then(function(result) {
			SearchViewer.setupLoginButton();
		}).catch(function(error) {
			AppUI.setTimeOutForLoadingDiv(false);
			Promise.reject(error);
		});
	});
}
