import BN from 'bn.js';
import { Common } from './common.js';
import { Namer } from './namer.js';
import { ContractLister } from './contractLister.js';
import type Web3 from 'web3';
import type { BlockNumber } from 'web3-core';
import type { AbiItem } from 'web3-utils';
import type {
	ABI,
	ABIWithNulls,
	BNFieldOfContractNamed,
	ContractName,
	FullRowData,
	FullRowDataMap,
	FunctionNameInTable,
	FunctionPropertiesOf,
	ListAttributeFromLengthFnName,
	OneChainNumberParamStringFunctionNameInTable,
	RowDataMappedFromContractName,
	TableDataFromName,
	TableName,
	Tables,
	TS200TableInstance
} from '../../ts200DataTypes.js';
import type {
	NormalFnABI
} from './truffleTypes.js';
import type { ChainNumber } from '../../dataCache/cacheManager.js';

type ListIteratorParamsTuple = [ChainNumber, ChainNumber];
export interface FunctionTypeAbiItem<T extends TS200TableInstance> extends AbiItem {
	type: 'function';
	name: FunctionNameInTable<T, ListIteratorParamsTuple>;
}
export interface ListInfo<
	TN extends TableName,
	LFN extends BNFieldOfContractNamed<TN> & keyof RowDataMappedFromContractName<TN> & string =
				BNFieldOfContractNamed<TN> & keyof RowDataMappedFromContractName<TN> & string
> {
	//Iterator is a full ABI; was: Exclude<ReturnType<typeof Chainreader.getIteratorIfIsListLengthFn>, undefined>;
	iteratorName: FunctionNameInTable<Tables[TN], ListIteratorParamsTuple>;
	attributeName : ListAttributeFromLengthFnName<LFN>;//should also be keyof RowDataMappedFromContractName<TN>;
	lengthFnName : LFN;
}
export const Chainreader = {
	//Documentation style: https://jsdoc.app/

	/**
	* Function to download data to a file.
	* @param data - the contents of the file
	* @param filename - the default suggested name of the file
	* @param type - the file type
	* Source: https://stackoverflow.com/a/30832210
	*/
	download: function(
		data : BlobPart,
		filename : string,
		type ?: string
	) {
		var file = new Blob([data], {type: type});
		var a = document.createElement('a'),
		url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}, 0);
	},

	/**
	* @returns {Promise} resolving to an array of IDs, each a BN object.
	* @param {BN} maxID
	*/
	getIDsToReadOnTableGivenMaxID: function(
		web3 : Web3,
		contractTable : TS200TableInstance,
		maxID : BN,
		includeInvalid = false
	) : Promise<BN[]> {
		return new Promise(async function(resolve, reject) {
			try {
				const isValidIDFnName = 'isValidID'; //from straightSrc/contracts/Table.sol
				if(isValidIDFnName in contractTable && typeof contractTable[isValidIDFnName] === 'undefined') {
					console.log('Warning: Likely programming bug. isValidID function not found in contract. Skipping validity checks.');
					includeInvalid = true; // NOSONAR due to https://community.sonarsource.com/t/fp-javascript-s1226-sonar-sometimes-doesnt-recognize-that-param-reassignment-is-conditional/70545
				}
				var idsToRead: BN[] = [];
				//shift by 1 is intentional here: valid ids start at 1 and include maxID.
				for(let rowID = new BN(1); rowID.lte(maxID); rowID.iaddn(1)) {
					let clonedRowID = rowID.clone();
					let includingRow : undefined | boolean = true;
					if(!includeInvalid) {
						includingRow = await Chainreader.rawReadWithRetries(
							web3,
							contractTable,
							[clonedRowID] as [BN], //Cast should not be necessary but appears to be at this stage of TS development
							isValidIDFnName
						);
					} //else, the boolean variable remains true
					if(includingRow) {
						idsToRead.push(clonedRowID);
					} //else, don't include that row
				}
				resolve(idsToRead);
			} catch(err) {
				reject(err);
			}
		});
	},

	/**
	* Gets a named function ABI by searching through the contract ABI linearly and
	* checking the 'name' field of each function.
	* @returns Function ABI (synchronously); undefined if not found.
	* @param contractABI the ABI of the contract to search through.
	* @param desiredFnName the name of the function for which the ABI is sought.
	* @param {evaluates as Boolean} nullOut If true, will MODIFY incoming ABI to
	* mark found function as null. Default false. Used by form.
	*/
	getFnABIByName: function(
		contractABI : ABIWithNulls,
		desiredFnName : string,
		nullOut = false,
		loggerIfNotFound : null | typeof console.log = console.log
	) {
		//Does code need to use .methods with Truffle-contract v4+ ?
		let methods = ''; //for error logging
		desiredFnName =  Chainreader.translateFnName(desiredFnName);
		for (let abiEntryNum = 0; abiEntryNum < contractABI.length; abiEntryNum++) {
			const abiEntry = contractABI[abiEntryNum];
			methods += ((abiEntryNum > 0) ? ',' : '') +
			(
				(abiEntry === null) ?
				'nullFn' :
				(Chainreader.getNameFromABIIfPresentPlaceholderOtherwise(abiEntry))
			);
			if(
				(abiEntry != null) && //Meant-to-be-skipped values nulled in form.js
				('name' in abiEntry && abiEntry.name == desiredFnName)
			) {
				//Found function named '+desiredFnName
				var retval = abiEntry;
				if(nullOut) {
					contractABI[abiEntryNum] = null;
				}
				return retval;
			}
		}
		if(desiredFnName == 'addChild' || desiredFnName == 'removeChild') {
			//These two excluded from output b/c by design Form will look for & not find them.
			loggerIfNotFound = null;
		}
		if((loggerIfNotFound !== undefined) && (loggerIfNotFound !== null)) {
			loggerIfNotFound('Could not find function named '+desiredFnName+' among methods '+methods);
		}
		return undefined;
	},

	getNameFromABIIfPresentPlaceholderOtherwise: function(
		abiEntry: AbiItem
	) {
		if('name' in abiEntry) {
			return abiEntry.name;
		} else {
			return 'noNameInABIEntry';
		}
	},

	/** Some translation for nonuniform naming in ts200l.
	*/
	translateFnName: function(
		desiredFnName : string
	) {
		if(ContractLister !== undefined && ContractLister.dbName === 'ts200l') {
			if(desiredFnName == 'partQualsEntryAt') {
				return 'partQualsIDat'; //in Cert & Part
			} else if(desiredFnName == 'techSpecsEntryAt') {
				return 'techSpecsIDat'; //in Part
			} else if(desiredFnName == 'childrenEntryAt') {
				return 'childrenIDat'; //in Family & TechSpec
			} else if(desiredFnName == 'certsListEntryAt') {
				return 'certsListIDat'; //in Company
			} else if(desiredFnName == 'mfgRoutesEntryAt') {
				return 'mfgRoutesIDat'; //in MCR
			} else if(desiredFnName == 'MCRsEntryAt') {
				return 'MCRsIDat'; //in MfgRoute
			} else {
				return desiredFnName;
			}
		} else {
			return desiredFnName
		}
	},

	/**
	* If the first parameter is a linked object, returns the name of the table for
	* the other object.  Else, returns undefined.
	* @returns (synchronously) String with the name of another table, or undefined.
	* @param disambiguatedFnABI a function ABI in which the first output object has a .targetType attribute
	*/
	getOtherTableNameIfIsLink: function(
		disambiguatedFnABI : NormalFnABI
	) { //returns synchronously
		const firstOutput = disambiguatedFnABI.outputs[0];
		if('targetType' in firstOutput && typeof firstOutput.targetType != 'undefined') {
			//Found target type present in getOtherTableNameIfIsLink.
			if('value' in firstOutput && Common.isPositiveIntString(firstOutput.value)) {
				//Found target type with nonzero value present in getOtherTableNameIfIsLink.
				return firstOutput.targetType;
			}
		}
		return undefined;
	},

	//DRAFT
	getInfoIfIsListLengthFn: function<T extends TS200TableInstance>(
		fnName : string,
		contractABI : ABI,
		contractName : TableName,
		nullOut : boolean = false
	) {
		//'If' conditions are from Explorer.getValueFromReadFn wrapping call to
		// handleIfIterableViewFunction, plus name
		if(Namer.isLengthFnByName(fnName)) {
			var otherFnABI = Chainreader.getFnABIByName(contractABI, Namer.iterationFnGivenLength(fnName), nullOut);
			if (Chainreader.looksLikeIterator<T>(otherFnABI)) {
				return otherFnABI;
			}
		}
		return undefined;
	},

	/** This was cut in https://gitlab.com/sae_devops/ts200/-/merge_requests/14/diffs?commit_id=c34cb22b77c727fd8c08d8f1200a14708fc75555
	* after removing getListInfo in https://gitlab.com/sae_devops/ts200/-/merge_requests/14/diffs?commit_id=d3d38ecdbdb9cb343122c03d7da731331b875a56
	* based on a search not showing up an occurrence where it was actually used, in currentStateCacheRebuilder.js.
	* In the sole calling context for getListInfo, the last 4 params were false, allowing the function to simplify down
	* to a call to this one.  This includes a note that the original param disambiguateType was false,
	* and removing that param allowed removal of the Explorer dependency which motivated removing this and related functions in that MR.
	* This fn is also called in fileCacheSyncer.fetchFromChain, where the disambiguateType param was also false.
	* If passed in a function which refers to the length of a list, get the function
	* which will allow access to a specific member.
	* Otherwise, return undefined.
	* @param {String} fnName Name of a function which may return the length of a list.
	* @param contractABI The ABI of the contract the function is part of.
	* @param contractName The name of the contract the function is part of.
	* @param nullOut passed on to getFnABIByName
	* @returns (synchronously) the function ABI for the iterator (function which
	* permits access to an individual list member), or 'undefined' if the fnABI is
	* not a list length function.
	*/
	 getIteratorIfIsListLengthFn: function<T extends TS200TableInstance>(
		fnName : string,
		contractABI : ABI,
		contractName : TableName,
		nullOut : boolean = false
	) {
		//'If' conditions are from Explorer.getValueFromReadFn wrapping call to
		// handleIfIterableViewFunction, plus name
		if(Namer.isLengthFnByName(fnName)) {
			var otherFnABI = Chainreader.getFnABIByName(contractABI, Namer.iterationFnGivenLength(fnName), nullOut);
			if (Chainreader.looksLikeIterator<T>(otherFnABI)) {
				return otherFnABI;
			}
		}
		return undefined;
	},

	looksLikeIterator: function<T extends TS200TableInstance>(
		fnABI ?: AbiItem
	) : fnABI is FunctionTypeAbiItem<T> {
		return ((typeof fnABI?.inputs !== 'undefined') && //also checks fnABI != undefined
		(fnABI.type === 'function') &&
		(fnABI.inputs.length==2) &&
		((fnABI.inputs[0].type.startsWith('uint')) || (fnABI.inputs[0].type.startsWith('bytes'))) &&
		(fnABI.inputs[1].type.startsWith('uint')));
	},

	makeCompoundIDWithFieldName: function(
		tableName : ContractName | 'trackedTable', //latter for tradDBSyncer
		elementID : ChainNumber,
		fieldName : string
	) {
		return Chainreader.addFieldToCompoundID(Chainreader.makeCompoundID(tableName, elementID), fieldName);
	},

	addFieldToCompoundID: function(
		compoundID : string,
		fieldName : string
	) {
		return compoundID+'.'+fieldName;
	},

	/** @returns {String}
	* In the future, this might also strip out the field name if .fieldName is included.
	*/
	getIDFromCompoundID: function(
		compoundID : string
	) {
		let lastDash = compoundID.lastIndexOf('-');
		return compoundID.substring(lastDash+1);
	},

	//elementID can be numeric or string
	makeCompoundID: function(
		tableName : string, //should eventually be TableName after India naming system is gone
		elementID : ChainNumber
	) {
		return tableName+Chainreader.makeEndOfCompoundID(elementID);
	},

	makeCompoundIDs: function(
		tableName : string, //should eventually be TableName after India naming system is gone
		elementIDs : ChainNumber[]
	) {
		let retval = [];
		for(let idIndex in elementIDs) {
			retval.push(Chainreader.makeCompoundID(tableName, elementIDs[idIndex]));
		}
		return retval;
	},

	makeEndOfCompoundID: function(
		elementID : ChainNumber
	) {
		return Common.isEmpty(elementID) ? '' : ('-'+elementID);
	},

	/**
	* This was removed in https://gitlab.com/sae_devops/ts200/-/merge_requests/14/diffs?commit_id=abde16f842ae3e8d9503a90dd0f307eb2875f21c
	* due to search not turning up a calling context in currentStateCacheRebuilder.js.
	* In that context, the dontExpandObj param is {any: true}, allowing that param to be dropped & getListElementsNextID simplified.
	* dbCacheObject should have a key with each compoundID (if missing, it'll assume length 0.)
	*/
	getListElementsIDSweep: async function<
		TN extends TableName,
	>(
		web3 : Web3,
		contractTable : Tables[TN],
		contractName : TN,
		idsExploring : ChainNumber[],
		tableCacheObject : TableDataFromName<TN>,
		listInfo : ListInfo<TN>
	) {
		try {
			const listLengthFieldNameNarrowed = listInfo.lengthFnName;
			let results = [];
			for(let idExploring of idsExploring) {
				let compoundID = Chainreader.makeCompoundID(contractName, idExploring);
				let lengthVal: ChainNumber = new BN(0); //default
				//Cast in next line should not be necessary: should be able to move it to type declaration on this const or omit,
				//but the TS compiler does not seem to understand this.
				const possibleLengthVal = tableCacheObject?.[compoundID]?.[listLengthFieldNameNarrowed] as BN | undefined;
				if(typeof possibleLengthVal !== 'undefined') {
					lengthVal = possibleLengthVal;
				}
				//lengthVal is a BN here.
				results.push(await Chainreader.getListMembers(
					web3,
					contractTable,
					idExploring,
					lengthVal,
					listInfo.iteratorName
				));
			}
			return (results);
		} catch(err) {
			console.log('Error in getListElementsIDSweep():',err);
			throw (err);
		}
	},

	/**
	* This was removed in https://gitlab.com/sae_devops/ts200/-/merge_requests/14/diffs?commit_id=b9a46efc87069983c11d14e0feb28b6aa8b40bd7
	* after a search failed to find the calling context due to an erroneous line in the .gitignore file.
	* @param blockNum see rawRead for documentation.
	*/
	getListMembersIncludingLength: function<
		T extends TS200TableInstance,
	>(
		web3 : Web3,
		contractTable : T,
		idExploring : ChainNumber,
		lengthFnName : FunctionNameInTable<T, [ChainNumber]>, //TODO: Add `Promise<BN>` as 3rd param to wrapped type FunctionPropertyName; now causes errors
		iteratorName : FunctionNameInTable<T, ListIteratorParamsTuple>,
		blockNum ?: BlockNumber
	) {
		return new Promise(function(resolve, reject) {
			Promise.resolve().then(function() {
				return Chainreader.rawReadWithRetries<T, [ChainNumber], typeof lengthFnName>(web3, contractTable, [idExploring], lengthFnName, blockNum);
			}).then(function (lengthVal: BN) {
				//lengthVal is a BN here.
				resolve(Chainreader.getListMembers(web3, contractTable, idExploring, lengthVal, iteratorName, blockNum));
			}).catch(function(err) {
				reject(err);
			});
		});
	},

	/**
	* This was removed in https://gitlab.com/sae_devops/ts200/-/merge_requests/14/diffs?commit_id=8ac68fe692c59cfd7f6b240991fbe3e077668345
	* after incorrectly cutting calling contexts getListElementsNextID & getListMembersIncludingLength
	* @returns {Promise} resolving to an array of strings representing list members (e.g. ids)
	* @param blockNum see rawRead for documentation.
	*/
	getListMembers: function<
		T extends TS200TableInstance,
		FN extends (FunctionNameInTable<T, ListIteratorParamsTuple>)
	>(
		web3 : Web3,
		contractTable : T,
		idExploring : ChainNumber,
		lengthVal : ChainNumber,
		iteratorName : FN,
		blockNum ?: BlockNumber
	) {
		var elementPromises = [];
		if(!BN.isBN(lengthVal)) {
			lengthVal = new BN(lengthVal);
		}
		for (let index = new BN(0); index.lt(lengthVal); index.iaddn(1)) {
			elementPromises.push(Chainreader.rawReadWithRetries<T, ListIteratorParamsTuple, FN>(
				web3,
				contractTable,
				[idExploring, index],
				iteratorName,
				blockNum
			));
		}
		return Promise.all(elementPromises);
	},

	/**
	* Calls rawRead and then Common.makeBigNumberString on the returned value.
	* @returns {Promise}
	* See rawRead for parameter documentation.
	*/
	rawReadWithRetriesAndBigNumberConversion: async function<
		T extends TS200TableInstance,
		P extends any[],
		FN extends FunctionNameInTable<T, P>
	>(
		web3 : Web3,
		contractTable : T,
		params : P,
		fnName : FN,
		blockNum ?: BlockNumber
	) {
		try {
			let returnVal = await Chainreader.rawReadWithRetries(web3, contractTable, params, fnName, blockNum);
			return (Common.makeBigNumberString(returnVal));
		} catch (err) {
			console.log('Error in rawReadWithRetriesAndBigNumberConversion call to '+fnName+': ',err);
			throw(err);
		}
	},

	/** Similar to multiReadObjectIdSweep, which returns an ARRAY of objects;
	* but this one returns an OBJECT where each row is a member
	* identified by its compoundID.
	*/
	multiReadObjectIdSweepCompoundIDs: async function<
		TN extends TableName,
		F extends FunctionNameInTable<Tables[TN], [ChainNumber]>,
		Fs extends F[],
	>(
		web3 : Web3,
		contractTable : Tables[TN],
		contractName : TN,
		idsToRead : ChainNumber[],
		readFnNames: Fs,
		bigNumberConversion = false,
		isInShutdown = function() {return false;}
	) : Promise<TableDataFromName<TN>> {
		const includeEmptyFields = false; //Was a param but SonarQube said that was too many
		try {
			let retval : TableDataFromName<TN> = {};
			let rowsArray = await Chainreader.multiReadObjectIdSweep<
				TN,
				F,
				Fs
			>(
				web3,
				contractTable,
				idsToRead,
				readFnNames,
				bigNumberConversion,
				includeEmptyFields,
				isInShutdown
			);
			if((rowsArray.length != idsToRead.length) && !isInShutdown()) {
				throw new Error('In multiReadObjectIdSweep, returned row count != idsToRead.length.');
			} else {
				for (let idIndex in idsToRead) {
					const compoundID = Chainreader.makeCompoundID(contractName, idsToRead[idIndex]);
					retval[compoundID] = rowsArray[idIndex];
				}
				return (retval);
			}
		} catch(err) {
			console.log('Error in multiReadObjectIdSweepCompoundIDs():',err);
			throw (err);
		}
	},

	/** For reading multiple fields (given by readFnNames) across a set of ids
	* The field functions should be previously checked to only take one input, the id.
	* This function uses a level of parallelism that can overwhelm a server.
	* Use multiReadObjectIdSweep instead, until the tech can take this.
	* Added unused.
	*/
	multiReadObjectIdSweepParallel: function<
		TN extends TableName,
		T extends Tables[TN],
		F extends FunctionNameInTable<Tables[TN], [ChainNumber]>,
		Fs extends F[],
	>(
		web3 : Web3,
		contractTable: T,
		idsToRead : ChainNumber[],
		readFnNames: Fs,
		bigNumberConversion = false
	) {
		let promises = [];
		for (let idIndex in idsToRead) {
			promises.push(Chainreader.multiReadObject<
				TN,
				[ChainNumber],
				F,
				Fs
			>(
				web3,
				contractTable,
				[idsToRead[idIndex]],
				readFnNames,
				bigNumberConversion
			));
		}
		return Promise.all(promises);
	},

	/** For reading multiple fields (given by readFnNames) across a set of ids
	* The field functions should be previously checked to only take one input, the id.
	*/
	multiReadObjectIdSweep: function<
		TN extends TableName,
		F extends FunctionNameInTable<Tables[TN], [ChainNumber]>,
		Fs extends F[],
	>(
		web3 : Web3,
		contractTable : Tables[TN],
		idsToRead : ChainNumber[],
		readFnNames : Fs,
		bigNumberConversion = false,
		includeEmptyFields = false,
		isInShutdown = function() {return false;}
	) : Promise<Array<RowDataMappedFromContractName<TN>>>{
		return new Promise(async function(resolve, reject) {
			try {
				let results = [];
				for(let idIndex = 0; (idIndex < idsToRead.length) && !isInShutdown(); idIndex++) {
					results.push(await Chainreader.multiReadObject<
						TN,
						[ChainNumber],
						F,
						Fs
					>(
						web3,
						contractTable,
						[idsToRead[idIndex]],
						readFnNames,
						bigNumberConversion,
						includeEmptyFields
					));
				}
				resolve(results);
			} catch(err) {
				console.log('Error in multiReadObjectIdSweep():',err);
				reject(err);
			}
		});
	},

	/** See documentation under multiRead below for input parameters.
	The resolve value of this function is an object, containing fields for each of the readFnNames
	*/
	multiReadObject: async function<
		TN extends TableName,
		P extends any[],
		F extends keyof RowDataMappedFromContractName<TN> & keyof FunctionPropertiesOf<Tables[TN], P> & keyof Tables[TN] & string,
		Fs extends F[],
	>(
		web3 : Web3,
		contractTable : Tables[TN],
		params : P,
		readFnNames : Fs,
		bigNumberConversion = false,
		includeEmptyFields = true
	) : Promise<RowDataMappedFromContractName<TN>> {
		try {
			const objectFields = await Chainreader.multiRead(
				web3,
				contractTable,
				params,
				readFnNames,
				bigNumberConversion
			);
			let retval : RowDataMappedFromContractName<TN> = {};
			for(let fnNameIndex = 0; fnNameIndex<readFnNames.length; fnNameIndex++) {
				const objectFieldValue = objectFields[fnNameIndex];
				//Not sure why cast is required & TS isn't picking up on this constraint from above.
				const readFnName = readFnNames[fnNameIndex] as keyof RowDataMappedFromContractName<TN>;
				if(includeEmptyFields || !Common.isEmpty(objectFieldValue)) {
					//Ideally, this next line would not require a cast, but specifying the return type of multiRead seems a bit complex
					retval[readFnName] = objectFieldValue as FullRowDataMap[TN][typeof readFnName];
				}
			}
			return (retval);
		} catch (err) {
			console.log('Error in multiReadObject call to functions',readFnNames,': ',err);
			throw (err);
		}
	},

	/**
	* Calls a blockchain function (done by local simulation), for multiple
	* read functions that all use the same set of parameters.
	*
	* @param {TruffleContract} contractTable The contract instance on which the function should be called.
	* @param {Array} params an ARRAY of parameters.
	* It will most commonly be a single-element array
	* containing a String or integer, representing the id of the row to read.
	* It should not be a BigNumber, because if it is the following error is presented:
	* Error in rawRead call to num:  TypeError: h(...).round is not a function
	at Object.toTwosComplement (inpage.js:1)
	at o.a [as _inputFormatter] (inpage.js:1)
	at o.encode (inpage.js:1)
	at inpage.js:1
	at Array.map (<anonymous>)
	at d.encodeParams (inpage.js:1)
	at u.toPayload (inpage.js:1)
	at u.call (inpage.js:1)
	at truffle-contract.js:136
	at new Promise (<anonymous>)
	* Should look into why, and fix that if appropriate.
	* @param {Array of Strings} readFnNames an ARRAY of function names.
	* @param {Boolean} bigNumberConversion if true, uses rawReadWithRetriesAndBigNumberConversion instead of rawRead. Optional; default false.
	* @returns {Promise} resolving to an array containing the values returned from the functions.
	*/
	multiRead: function<
		T extends TS200TableInstance,
		P extends any[],
		FN extends FunctionNameInTable<T, P>,
		FNTuple extends FN[],
	>(
		web3 : Web3,
		contractTable : T,
		params: P,
		readFnNames : FNTuple,
		bigNumberConversion = false
	) { //TODO: Annotation for return type (it's complex!)
		let readPromises = [];
		for(let readFnName of readFnNames) {
			if(bigNumberConversion) {
				readPromises.push(Chainreader.rawReadWithRetriesAndBigNumberConversion(web3, contractTable, params, readFnName));
			} else {
				readPromises.push(Chainreader.rawReadWithRetries(web3, contractTable, params, readFnName));
			}
		}
		return Promise.all(readPromises);
	},

	/** Synchronous.
	*/
	extractNamesFromABIs: function<
		T extends TS200TableInstance
	>(
		fnABIset : NormalFnABI[],
		contractTable: T,
		excludeNames : string[] //TODO: Make stricter?
	) {
		let retval : OneChainNumberParamStringFunctionNameInTable<T>[] = [];
		for(let fnABI of fnABIset) {
			const fnName = fnABI.name;
			if(
				fnName in contractTable &&
				!excludeNames.includes(fnName) &&
				//Cast in next line shouldn't be necessary due to the 'in' narrowing above, but is:
				typeof contractTable[fnName as keyof typeof contractTable] === 'function' &&
				fnABI.inputs.length === 1 &&
				fnABI.inputs[0].type.startsWith('uint') || fnABI.inputs[0].type.startsWith('int')
			) {
				retval.push(fnName as (string & FunctionNameInTable<T, [ChainNumber]>));
			}
		}
		return retval;
	},

	/**
	* Calls a blockchain function (done by local simulation), for read functions.
	* @returns {Promise}
	* @param {TruffleContract} contractTable The contract instance on which the function should be called.
	* @param {Array} params an ARRAY of values passed to the function.
	* @param {String} fnName The name of the function to be called.
	* @param {Number|String|BN|BigNumber|undefined} blockNum
	*/
	rawRead: async function<
		T extends TS200TableInstance,
		P extends any[], //fn params tuple
		FN extends FunctionNameInTable<T, P>
	>(
		web3 : Web3,
		contractTable : T,
		params : P,
		fnName : FN,
		blockNum ?: BlockNumber
	) : Promise<(FullRowData<T>[FN])> {
		if(blockNum === undefined) {
			blockNum = web3.eth.defaultBlock;
		}
		if(contractTable[fnName] === undefined) {
			throw new Error ('Error: Function '+fnName+' is undefined in contract.');
		} else {
			//@ts-ignore due to https://github.com/dethcrypto/TypeChain/issues/683
			return contractTable[fnName].call(...params, blockNum);
		}
	},

	rawReadWithRetries: async function<
		T extends TS200TableInstance,
		P extends any[], //fn params tuple
		FN extends FunctionNameInTable<T, P>
	>(
		web3 : Web3,
		contractTable : T,
		params : P,
		fnName : FN,
		blockNum ?: BlockNumber,
		retriesLeft = 60
	) : Promise<(FullRowData<T>[FN])> { //Can't do a cast like `Promise<ReturnType<(T[FN] as ((...args: P) => any))>>`
		try {
			let returnVal = await Chainreader.rawRead(web3, contractTable, params, fnName, blockNum);
			if(retriesLeft < 60) {
				console.log('Successful read of '+fnName+'('+params.join(', ')+') completed on a non-first try, with ' + retriesLeft + ' possible retries no longer needed. Result: ' + returnVal);
			}
			return returnVal;
		} catch (err: any) {
			if(retriesLeft <= 0) {
				err.retriedTimes = 60; //should match default for retriesLeft in this fn.
				throw (err);
			} {
				//Sometimes there is a message key and sometimes not, just the hijackedStack. Unclear why.
				if(
					(err.message !== undefined && err.message.includes('execution aborted')) ||
					(err.hijackedStack !== undefined && err.hijackedStack.includes('execution aborted')) ||
					(err.message.trim().endsWith('header not found')) || //common when connection to accessNode is lost, which might be temporary
					(err.message.includes('Provider started to reconnect before the response got received!'))
				) {
					console.warn('Warning: Got error message '+err.message+' when trying to read '+fnName+'('+params.join(', ')+'). Will try again up to '+retriesLeft+' times after a delay of 1 second each.');
					await Common.wait(1000);
					return (Chainreader.rawReadWithRetries(web3, contractTable, params, fnName, blockNum, retriesLeft - 1));
				} else if (err.message.includes('Maximum number of reconnect attempts reached!')) {
					const message = 'Error in rawRead call to '+fnName+'('+params.join(', ')+'): Connection failed after maximum reconnect attempts.';
					console.log(message);
					throw new Error(message);
				} else {
					console.log('Error in rawRead call to '+fnName+'('+params.join(', ')+') OTHER than execution aborted with web3 version', web3.version, '; err has keys [',Object.keys(err).join(', '),']:', err);
					throw new Error(err);
				}
			}
		}
	},

	/**
	* @returns {Promise}
	* Takes as input an ABI and list of fields.
	* Removes fields that don't appear to be valid, console logging a warning for each.
	* Returns the possibly-smaller set, via promise resolution.
	* Function ABIs are returned instead of just field names.
	* Special case & default: If the fieldsToRead input is an empty array, returns all valid fields.
	*/
	validateReadFields: async function(
		contractABI : ABI,
		fieldsToRead : string[] = [] //TODO: stricter type?
	) : Promise<NormalFnABI[]> {
		var abis = [];
		if(!Array.isArray(fieldsToRead)) {
			throw new Error('Programming error: fieldsToRead passed to validateReadFields is not an array.');
		} else {
			//Generate list. Does linear-in-size-of-ABI pass even if functions are specified.
			//Adapted from Explorer.readAllViewFields.
			for (let fnABI of contractABI) {
				if (Common.isFieldFn(fnABI)) {
					//Field function found: +fnABI.name
					if(fieldsToRead.length == 0 || ('name' in fnABI && fieldsToRead.includes(fnABI.name))) {
						//'If' wrapper not needed here if list case handled separately above.
						abis.push(fnABI);
					}
				//} else {
					//console.log('Non-Field function found: '+fnABI.name);
				}
			}
			//Resolving with the following fields to read: ', abis
			return(abis);
		}
	},
};
