import { AppUI } from './appUI.js';
interface AttributesObj {
	[index: string]: string
}
export const ScriptLoader = {
	runTasksImmediatelyOnPageLoad: function() {
		//This must be done within the first second of pageload to silence warnings.
		ScriptLoader.setMetaMaskToReloadOnNetworkChange();
		//This should be done quickly for display purposes:
		ScriptLoader.loadFavicons().catch(function(err) {console.error('Error loading favicons: ', err)});
	},

	setMetaMaskToReloadOnNetworkChange: function() {
		//This must be done within the first second of pageload to silence warnings.
		if (typeof ethereum !== 'undefined' && ethereum.isMetaMask) {
			ethereum.on('chainChanged', function (_chainId: string) {
				const lastEthChain = window.sessionStorage.getItem('lastEthChain');
				//The first time the dapp is loaded, if chainChanged is fired despite
				//lack of actual chain change (due to MetaMask issue 9421);
				//lastEthChain will be null and so the page will reload...
				//but just once, not infinitely many times.
				//The issue was migrated to https://github.com/MetaMask/providers/issues/110
				//and appears to have been fixed in https://github.com/MetaMask/providers/pull/117 released early 2021.
				if(lastEthChain !== _chainId) {
					console.log('Reloading due to chainChanged event. lastEthChain:',typeof lastEthChain,lastEthChain,' newChain:',typeof _chainId, _chainId,'.');
					window.sessionStorage.setItem('lastEthChain', _chainId);
					window.location.reload();
				} else {
					console.log('https://github.com/MetaMask/metamask-extension/issues/9421 is still an open issue. You are still on chain '+_chainId);
				}
			});
		}
	},

	loadTruffleContract: function() {
		return Promise.resolve().then(function() {
			return ScriptLoader.loadScripts([
				'/js/bn.js',
			]);
		}).then(function() {
			return ScriptLoader.loadScripts([
				'../js/web3.min.js', //relies on BN.js to have been previously loaded.
			]);
		}).then(function() {
			window.web3 = new window.Web3();
			return ScriptLoader.loadScripts([
				'/js/truffle-contract.min.js', //web3.js is a prerequisite
			]);
		}).then(function() {
			return Promise.resolve();
		}).catch(function(error) {
			return Promise.reject(error);
		});
	},

	loadAdminPageScripts: function() {
		//Scripts used in subLevelIndex.html
		return Promise.resolve().then(function() {
			AppUI.setTimeOutForLoadingDiv(true);
			return ScriptLoader.loadTruffleContract();
		}).catch(function(error) {
			AppUI.setTimeOutForLoadingDiv(false);
			Promise.reject(error);
		});
	},

	/** @param {Array of Strings} scripts: an array of filenames, often of the form
	* start with 'js/....js'
	*/
	loadScripts: function(scripts: string[] = [], modules: string[] = []) {
		$.ajaxSetup({
			cache: true
		});
		var promises = [];
		for(let script of scripts) {
			promises.push(ScriptLoader.loadScript(script));
		}
		for(let script of modules) {
			promises.push(ScriptLoader.loadScript(script, true));
		}
		return ScriptLoader.resetCacheAfterPromisesComplete(promises, 'loading scripts');
	},

	loadFavicons: function() {
		$.ajaxSetup({
			cache: true
		});
		let promises = [];
		promises.push(ScriptLoader.addHeadTag(
			'link',
			{
				rel: 'apple-touch-icon',
				sizes: '180x180',
				href: '/apple-touch-icon.png',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'link',
			{
				rel: 'icon',
				type: 'image/png',
				sizes: '32x32',
				href: '/favicon-32x32.png',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'link',
			{
				rel: 'icon',
				type: 'image/png',
				sizes: '16x16',
				href: 'favicon-16x16.png',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'link',
			{
				rel: 'manifest',
				href: '/site.webmanifest',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'link',
			{
				rel: 'mask-icon',
				color: '#00773d',
				href: '/safari-pinned-tab.svg',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'meta',
			{
				name: 'msapplication-TileColor',
				content: '#00773d',
			}
		));
		promises.push(ScriptLoader.addHeadTag(
			'meta',
			{
				name: 'theme-color',
				content: '#ffffff',
			}
		));
		return ScriptLoader.resetCacheAfterPromisesComplete(promises, 'loading favicons');
	},

	resetCacheAfterPromisesComplete: function(promises: Promise<any>[], actionSummaryForError: string) : Promise<void> {
		return new Promise(function(resolve, reject) {
			Promise.all(promises).then(function() {
				//Reset default:
				$.ajaxSetup({
					cache: false
				});
				resolve();
			}).catch(function(error) {
				console.log('Error ' + actionSummaryForError + ': ',error);
				reject();
			});
		});
	},

	/** @returns {Promise}
	*/
	loadScript: function(scriptPath: string, isModule = false) {
		let attributes : AttributesObj = {src: scriptPath};
		if(isModule) {
			attributes.type = 'module';
		}
		return ScriptLoader.addHeadTag('script', attributes, true);
	},

	addHeadTag: function(tagType: keyof HTMLElementTagNameMap, attributes: AttributesObj, addToBodyInstead = false) : Promise<void> {
		return new Promise(function(resolve, reject) {
			let tag = document.createElement(tagType);
			tag.onload = function() {
				resolve();
			}
			tag.onerror = function(errEvent) {
				console.log('Error loading ' + tagType + ' with attributes ', attributes, ':', errEvent);
				reject(errEvent);
			}
			for (let attributeName in attributes) {
				tag.setAttribute(attributeName, attributes[attributeName]);
			}
			if(addToBodyInstead) {
				document.body.append(tag);
			} else {
				document.head.append(tag);
			}
		});
	},

	/**
	* Not used due to https://bugs.chromium.org/p/chromium/issues/detail?id=1121241
	*/
	loadScriptJQuery: function(scriptPath: string) {
		return new Promise(function(resolve, reject) {
			return $.getScript(scriptPath).
			done(function(script, textStatus) {
				resolve(textStatus);
			}).fail(function(jqxhr, settings, exception) {
				reject({statusCode: jqxhr.status, exception: exception})
			});
		});
	},

	loadAdminHeader: function(tabIDToSetActive: string) : Promise<void> {
		return new Promise(function(resolve, reject) {
			$('#headerBar').load('/header.html',function() {
				$('#headerNavBar').load('/headerNav.html',function() {
					$('.nav li').removeClass('active');
					let tabToSetActive = document.getElementById(tabIDToSetActive);
					if(tabToSetActive === null) {
						console.warn('Programming bug: could not find '+ tabToSetActive +' tab to set active.');
					} else {
						tabToSetActive.classList.add('active');
						tabToSetActive.classList.remove('hidden'); //removes if present
					}
					resolve();
				});
			});
		});
	},

};
