export const sharedConfiguration = {
    ipConfig: {
        'serverDescription': 'prod',
        'ipAddress': '127.0.0.1',
        'rootURL': 'https://ts200-beta.sae-itc.org/',
        'authSchemeName': 'Signature',
        'authAlgoName': 'soliditySha3',
    },
    servers: {
        'accessNode': {
            'port': '7550',
            'networkID': '5777',//'7234827',
        },
    },
    blockchain: {
        'host': '127.0.0.1',
        'httpPort': '7550',
        'webSocketPort': '8546',
        'network_id': '5777', //underscore format to match truffle-config
    },
    paths: {
        'ts200DataDir': '/ts200/data/ts200DataBaseFile/',
    },
}

