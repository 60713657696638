import type Express from 'express';
import type { TableName } from '../../ts200DataTypes';
export const ContractLister = {
	contractMiddlewareList: function(app: Express.Express, express: typeof Express) {
		app.use('/Part*', express.static('base/explorerFiles/index.html'));
		app.use('/Cert*', express.static('base/explorerFiles/index.html'));
		app.use('/MCR*', express.static('base/explorerFiles/index.html'));
		app.use('/MfgRoute*', express.static('base/explorerFiles/index.html'));
		app.use('/PartQual*', express.static('base/explorerFiles/index.html'));
		app.use('/TechSpec*', express.static('base/explorerFiles/index.html'));
		app.use('/TestReport*', express.static('base/explorerFiles/index.html'));
		app.use('/Company*', express.static('base/explorerFiles/index.html'));
		return {app, express};
	},

	availableContracts: [
		'Part',
		'Cert',
		'MCR',
		'MfgRoute',
		'PartQual',
		'TechSpec',
		'TestReport',
		'Company'
	] as TableName[],

	dbName: 'ts200l',

	isAvailableContract: function(nameToTest: TableName) : boolean {
		return ContractLister.availableContracts.includes(nameToTest);
	},
};
