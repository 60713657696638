import { AppUI } from './appUI.js';
import { AlphaNumericSorter } from './alphaNumericSorter.js';
import { Common } from './common.js';
import { ContractLister } from './contractLister.js';
import { DateUtils } from './dateUtils.js';
export const SearchViewer = {
	account: 0x0,
	adminAccountFlag: false,
	start: 0,
	rows: 5,
	currentPgNo: 0,
	previouspage: 0,
	lastPage: 0,
	totalRecords: 0,
	indexDocs: [],
	searchTextVal: '',
	searchByVal: '',
	pageId: '',
	expiryDateCountOption: 0,
	ManufacturerCountOption: 0,
	certificateCountOption: 0,
	techSpecCountOption: 0,
	selected_techspecNum: [],
	selected_companyName_facet: [],
	selected_tssCertNo: [],
	expiryFacetRes: '',
	expiryFacetRessorted: '',
	monthList: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	selected_expiryDate: [],
	// unique string which is appended to each part, while updating 'QualifiedChildPartsHierarchy' attribute in each solr record
	uniquePartIdentifier: '_partId_',
	clonePaginationWidgetToBottom: true,

	//TODO: DRY with same-named function in certViewer.ejs
	allowAdminFunction: function(userAccount) {
		if(Common.tables['Company']!= undefined) {
			var owner = Common.tables['Company'].owner.call().
			then(function(ownerAccount) {
				if (ownerAccount == userAccount) {
					SearchViewer.adminAccountFlag = true;
				}
				SearchViewer.getPageDetailsIfResDataPresent();
			}).catch(function(err) {
				console.error(err.message);
				AppUI.setTimeOutForLoadingDiv(false);
				Promise.reject(err);
			});
			Promise.resolve(owner);
		}
	},

	setupLoginButton: function() {
		$('#loginButton').on('click',function() {
			SearchViewer.checkMetaMaskAccount();
		});
		AppUI.showLoginButtonIfWeb3ProviderIsMetaMask();
		if((sessionStorage['isEnabled']!=undefined) && (sessionStorage['isEnabled']=='true')) {
			//checkMetaMaskAccount calls getPageDetailsIfResDataPresent, in some conditions if
			//the resolve value from requestAccountConnection is truthy
			SearchViewer.checkMetaMaskAccount();
		}
	},

	getPageDetails: function() {
		SearchViewer.setFieldValues(window.location.href);
		const responseDataUnparsed = $('#resData').val();
		var responseData = {};
		if(responseDataUnparsed == 'noData') {
			//console.warn('No response data found.');
		} else {
			try {
				responseData = JSON.parse(responseDataUnparsed);
			} catch(err) {
				console.error('Error parsing response data '+responseDataUnparsed);
			}
		}
		SearchViewer.expiryFacetRes = responseData?.facet_counts?.facet_pivot;
		document.getElementById('responseBody').innerHTML = '';
		SearchViewer.indexDocs = typeof responseData?.response?.docs === 'undefined' ? [] : responseData.response.docs;
		SearchViewer.totalRecords = responseData?.response?.numFound;
		//console.log('SearchViewer.totalRecords is ',SearchViewer.totalRecords);
		SearchViewer.showFilterOptions(SearchViewer.totalRecords != 0);
		SearchViewer.toDisplayFilterResult(responseData?.facet_counts?.facet_fields);
		//SearchViewer.toDisplayPagination(SearchViewer.totalRecords, SearchViewer.rows);
		SearchViewer.toApplyPaginationWithDot($('#pageNo').val(),SearchViewer.totalRecords,$('#perPageSelect option:selected').text());
		SearchViewer.toApplyActiveClass($('#pageNo').val());
		SearchViewer.toApplyPaginationAtBottom();
		SearchViewer.showSearchRecords($('#pageNo').val(), false);
		AppUI.setTimeOutForLoadingDiv(false);
	},

	toDisplayFilterResult: function(filterRes) {
		SearchViewer.blankOutFilterSections();
		SearchViewer.displayTechSpecFacet(filterRes);
		SearchViewer.displayCompanyFacet(filterRes);
		SearchViewer.displayCertNumberFacet(filterRes);
		SearchViewer.displayExpirationDateFacet(filterRes);
		$('#companyName_facet').val('false');
		$('#techspecNum').val('false');
		$('#tssCertNo').val('false');
		SearchViewer.collapseFiltersWithMoreThan5Options(filterRes);
	},

	blankOutFilterSections: function() {
		SearchViewer.blankElementByID('techSpecFilterSec');
		SearchViewer.blankElementByID('companyFilterSec');
		SearchViewer.blankElementByID('certNumFilterSec');
		SearchViewer.blankElementByID('expDateFilterSec');
	},

	blankElementByID: function(elementID) {
		const elementToBlank = document.getElementById(elementID);
		if(elementToBlank != null) {
			elementToBlank.innerHTML = '';
		}
	},

	displayTechSpecFacet: function(filterRes) {
		if(filterRes?.techspecNum) {
			var tempTechSpec = '';
			//document.getElementById('techSpecCount').innerHTML = '&nbsp;<strong>('+filterRes.techspecNum.length/2+' Options)</strong>';
			SearchViewer.techSpecCountOption = filterRes.techspecNum.length/2;
			if(document.getElementById('techspecNum').value !== false) {
				tempTechSpec = document.getElementById('techspecNum').value.split(',');
			}
			var techSpecObject = {
				'techSpecArray': [],
				'count': {}
			};
			let techSpecArrayLength = filterRes.techspecNum.length;
			//filterRes.techspecNum has a pretty odd structure: it's an array where the even numbered elements (starting with 0)
			//are Strings such as 'nasm25027' or 'ts104' and the odd-numbered entry after is an Integer type indicating the number of
			//qualifications to that tech spec.
			//That probably should be made more sensible, but it's a nontrivial effort to trace through all posssible impacts of that.
			for(let i = 0; i<techSpecArrayLength; i += 2) {
				var techSpec = filterRes.techspecNum[i];
				techSpecObject['techSpecArray'].push(techSpec);
				techSpecObject['techSpecArray'][techSpec] = filterRes.techspecNum[i+1];
			}
			techSpecObject['techSpecArray'].sort(AlphaNumericSorter.alphanumCompare);
			for(let techSpecArrayMember of techSpecObject['techSpecArray']) {
				if(SearchViewer.getCheckedFlag(tempTechSpec,techSpecArrayMember)) {
					document.getElementById('techSpecFilterSec').innerHTML += '<input type="checkbox" name="techspecNum" class="field-distance font-normal-weight margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;techspecNum&quot;,&quot;'+techSpecArrayMember+'&quot;,this)" id="'+techSpecArrayMember+'" value="'+techSpecArrayMember+'" checked>'
					+'<label class="font-normal-weight" for="'+techSpecArrayMember+'">'+techSpecArrayMember.toUpperCase()+'</label>&nbsp;';
				} else {
					document.getElementById('techSpecFilterSec').innerHTML += '<input type="checkbox" name="techspecNum" class="field-distance font-normal-weight margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;techspecNum&quot;,&quot;'+techSpecArrayMember+'&quot;,this)" id="'+techSpecArrayMember+'" value="'+techSpecArrayMember+'">'
					+'<label for="'+techSpecArrayMember+'" class="font-normal-weight">'+techSpecArrayMember.toUpperCase()+'</label>&nbsp;';
				}
				document.getElementById('techSpecFilterSec').innerHTML += '<label class="font-normal-weight">('+techSpecObject['techSpecArray'][techSpecArrayMember]+')</label><br/>';
			}
			SearchViewer.getCheckedCount('techspecNum','techSpecCount');
		} else {
			SearchViewer.getCheckedCount('techspecNum','techSpecCount');
			document.getElementById('techSpecSec').innerHTML = '';
		}
	},

	displayCompanyFacet(filterRes) {
		if(filterRes?.companyName_facet) {
			var tempManufacturer = '';
			if(document.getElementById('companyName_facet').value !== false) {
				tempManufacturer = document.getElementById('companyName_facet').value.split(',');
			}
			var manufacturerObject = {
				'ManufacturerArray': [],
				'count': {}
			};
			//document.getElementById('ManufacturerCount').innerHTML = '&nbsp<strong>('+filterRes.companyName_facet.length/2+' Options)</strong>';
			//filterRes.companyName_facet has a pretty odd structure: it's an array where the even numbered elements (starting with 0)
			//are Strings each representing the name of a company and the odd-numbered entry after is an Integer type indicating the number of
			//qualifications held by that company.
			//That probably should be made more sensible, but it's a nontrivial effort to trace through all posssible impacts of that.
			SearchViewer.ManufacturerCountOption = filterRes.companyName_facet.length/2;
			for(let j = 0; j < filterRes.companyName_facet.length; j+= 2) {
				var companyName = filterRes.companyName_facet[j];
				manufacturerObject['ManufacturerArray'].push(companyName);
				manufacturerObject['count'][companyName] = filterRes.companyName_facet[j+1];
			}
			manufacturerObject['ManufacturerArray'].sort();
			for(let manufacturer of manufacturerObject['ManufacturerArray']) {
				if(manufacturer.length>20) {
					var company = manufacturer.substring(0,20)+'...';
				} else {
					company = manufacturer;
				}
				if(SearchViewer.getCheckedFlag(tempManufacturer, manufacturer)) {
					document.getElementById('companyFilterSec').innerHTML += '<input type="checkbox" name="companyName_facet" class="field-distance font-normal-weight margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;companyName_facet&quot;,&quot;'+manufacturer+'&quot;,this)" id="'+manufacturer+'" value="'+manufacturer+'" checked>'
					+'<label class="font-normal-weight" for="'+ manufacturer+'" data-toggle="tooltip" title="'+ manufacturer+'">'+company+'</label>&nbsp;';
				} else {
					document.getElementById('companyFilterSec').innerHTML += '<input type="checkbox" name="companyName_facet" class="field-distance font-normal-weight margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;companyName_facet&quot;,&quot;'+manufacturer+'&quot;,this)" id="'+manufacturer+'" value="'+manufacturer+'" >'
					+'<label class="font-normal-weight" for="'+ manufacturer+'" data-toggle="tooltip" title="'+manufacturer+'">'+company+'</label>&nbsp;';
				}
				document.getElementById('companyFilterSec').innerHTML += '<label class="font-normal-weight">('+manufacturerObject['count'][manufacturer]+')</label><br/>';
			}
			SearchViewer.getCheckedCount('companyName_facet','ManufacturerCount');
		} else {
			SearchViewer.getCheckedCount('companyName_facet','ManufacturerCount');
			document.getElementById('manufacturerSec').innerHTML = '';
		}
	},

	displayCertNumberFacet: function(filterRes) {
		if(filterRes?.tssCertNo) {
			var tempTssCertNo = '';
			if(document.getElementById('tssCertNo').value !== false) {
				tempTssCertNo = document.getElementById('tssCertNo').value.split(',');
			}
			var certificateObject = {
				'certificateArray': [],
				'count': {}
			};
			document.getElementById('certificateCount').innerHTML = '&nbsp;<strong>('+filterRes.tssCertNo.length/2+' Options)</strong>';
			SearchViewer.certificateCountOption = filterRes.tssCertNo.length/2;
			var certificateArrayLength = filterRes.tssCertNo.length;
			//filterRes.tssCertNo has a pretty odd structure: it's an array where the even numbered elements (starting with 0)
			//are Strings each representing the TSSC Certificate number such as '25' or '26 Addendum No 1'
			//and the odd-numbered entry after is an Integer type indicating the number of
			//qualifications on that certificate.
			//That probably should be made more sensible, but it's a nontrivial effort to trace through all posssible impacts of that.
			for(let i = 0; i <certificateArrayLength; i += 2) {
				var certificateNo = filterRes.tssCertNo[i];
				certificateObject['certificateArray'].push(certificateNo);
				certificateObject['count'][certificateNo] = filterRes.tssCertNo[i+1];
			}
			certificateObject['certificateArray'].sort(AlphaNumericSorter.alphanumCompare);
			for(let certificate of certificateObject['certificateArray']) {
				if(SearchViewer.getCheckedFlag(tempTssCertNo, certificate)) {
					document.getElementById('certNumFilterSec').innerHTML += '<input type="checkbox" name="tssCertNo" class="field-distance margin-field font-normal-weight" onclick="SearchViewer.toGetFilteredSearchResult(&quot;tssCertNo&quot;,&quot;'+certificate+'&quot;,this)" id="'+certificate+'" value="'+certificate+'" checked>'
					+'<label data-toggle="tooltip" class="font-normal-weight" for="'+certificate+'" title="'+certificate+'">TSSC #'+certificate+'</label>&nbsp;';
				} else {
					document.getElementById('certNumFilterSec').innerHTML += '<input type="checkbox" name="tssCertNo" class="field-distance margin-field font-normal-weight" onclick="SearchViewer.toGetFilteredSearchResult(&quot;tssCertNo&quot;,&quot;'+certificate+'&quot;,this)" id="'+certificate+'" value="'+certificate+'">'
					+'<label class="font-normal-weight" data-toggle="tooltip" for="'+certificate+'" title="'+certificate+'">TSSC #'+certificate+'</label>&nbsp;';
				}
				document.getElementById('certNumFilterSec').innerHTML += '<label class="font-normal-weight">('+certificateObject['count'][certificate]+')</label><br/>';
			}
			SearchViewer.getCheckedCount('tssCertNo','certificateCount');
		} else {
			SearchViewer.getCheckedCount('tssCertNo','certificateCount');
			document.getElementById('certificateSec').innerHTML = '';
		}
	},

	displayExpirationDateFacet: function() {
		if(SearchViewer?.expiryFacetRes && (SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth']?.length > 0)) {
			var monthCount = 0;
			var tempExpiryDate = '';
			if(document.getElementById('expiryDate').value !== false) {
				tempExpiryDate = document.getElementById('expiryDate').value.split(',');
				SearchViewer.getExpiryCheckedData();
			} //else, tempExpiryDate = '' per default assignment above
			var yearArrayLength = SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'].length;
			var dateobject = {
				'arr': [],
				'count': {}
			};
			for(let k = 0;k<yearArrayLength;k++) {
				var currentExpiryDateYear = SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'][k].value;
				dateobject['arr'].push(currentExpiryDateYear);
				dateobject['count'][currentExpiryDateYear] = SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'][k].count;
			}
			dateobject['arr'].sort(function (a, b) {return b-a;});
			SearchViewer.expiryFacetRessorted = dateobject;
			for(let i = 0; i < dateobject['arr'].length; i++) {
				for(let yearArraycount = 0;yearArraycount<yearArrayLength;yearArraycount++) {
					if(SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'][yearArraycount].value==dateobject['arr'][i]) {
						var monthArrayLength = SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'][yearArraycount].pivot.length;
						if(monthArrayLength > 0) {
							monthCount = monthCount+monthArrayLength;
							var tempDate = dateobject['arr'][i] + '-' + 0;
							if(SearchViewer.getCheckedFlag(tempExpiryDate,tempDate)) {
								document.getElementById('expDateFilterSec').innerHTML += '<div id="expiryDate'+i
								+'">'+
								'<div id="expiryYear'+i+'">'+
								'<input type="checkbox" name="expiryYearDate" class="field-distance margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;expiryDate&quot;,&quot;'+dateobject['arr'][i]+'-'+0+'&quot;,this)" id="expiryDateYear'+i+'" value="'+dateobject['arr'][i]+'" checked>'
								+'<label for="expiryDateYear'+i+'" class="font-normal-weight">'+dateobject['arr'][i]+'</label>'+
								'<label class="font-normal-weight">&nbsp;('+dateobject['count'][dateobject['arr'][i]]+')</label>'+
								'<span class="glyphicon glyphicon-menu-up margin-field-20 bold" data-toggle="collapse" data-target="#expiryDateMonthsSec'+i+'" onClick="SearchViewer.changeIcon(this)"></span>'+
								'</div>'+
								'<div id="expiryDateMonthsSec'+i+'" class="collapse in margin-field-20">';
							} else {
								document.getElementById('expDateFilterSec').innerHTML += '<div id="expiryDate'+i
								+'">'+
								'<div id="expiryYear'+i+'">'+
								'<input type="checkbox" name="expiryYearDate" class="field-distance margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;expiryDate&quot;,&quot;'+dateobject['arr'][i]+'-'+0+'&quot;,this)" id="expiryDateYear'+i+'" value="'+dateobject['arr'][i]+'">'
								+'<label for="expiryDateYear'+i+'" class="font-normal-weight">'+dateobject['arr'][i]+'</label>'+
								'<label class="font-normal-weight">&nbsp;('+dateobject['count'][dateobject['arr'][i]]+')</label>'+
								'<span class="glyphicon glyphicon-menu-up margin-field-20 bold" data-toggle="collapse" data-target="#expiryDateMonthsSec'+i+'" onClick="SearchViewer.changeIcon(this)"></span>'+
								'</div>'+
								'<div id="expiryDateMonthsSec'+i+'" class="collapse in margin-field-20">';
							}
							var monthObject = {
								'monthArray': [],
								'count': {}
							}
							for(let monthElement of SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth'][yearArraycount].pivot) {
								var currentExpiryDateMonth = monthElement.value;
								if(currentExpiryDateMonth!='' && currentExpiryDateMonth!=undefined) {
									monthObject['monthArray'].push(parseInt(currentExpiryDateMonth));
									monthObject['count'][currentExpiryDateMonth] = monthElement.count;
								}
							}
							monthObject['monthArray'].sort(function (a, b) {return a - b;});
							for(let j = 0; j < monthObject['monthArray'].length; j++) {
								tempDate = dateobject['arr'][i] + '-' + monthObject['monthArray'][j];
								var currentMonthName = SearchViewer.monthList[monthObject['monthArray'][j] - 1];
								if(SearchViewer.getCheckedFlag(tempExpiryDate, tempDate)) {
									document.getElementById('expiryDateMonthsSec'+i).innerHTML += '<div id="expiryDateMonth'+j+'">'+
									'<input type="checkbox" name="expiryMonthDate" class="field-distance margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;expiryDate&quot;,&quot;'+tempDate+'&quot;,this)"" id="expiryDateMonth'+j+''+i+'" value="'+monthObject['monthArray'][j]+'" checked><label for="expiryDateMonth'+j+''+i+'" class="font-normal-weight">'+currentMonthName+'</label>'+
									'<label class="font-normal-weight">&nbsp;('+monthObject['count'][monthObject['monthArray'][j]]+')</label><br/>'+
									'</div>';
								} else {
									document.getElementById('expiryDateMonthsSec'+i).innerHTML += '<div id="expiryDateMonth'+j+'">'+
									'<input type="checkbox" name="expiryMonthDate" class="field-distance margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;expiryDate&quot;,&quot;'+tempDate+'&quot;,this)" id="expiryDateMonth'+j+''+i+'" value="'+monthObject['monthArray'][j]+'"><label for="expiryDateMonth'+j+''+i+'" class="font-normal-weight">'+currentMonthName+'</label>'+
									'<label class="font-normal-weight">&nbsp;('+monthObject['count'][monthObject['monthArray'][j]]+')</label><br/>'+
									'</div>';
								}
							}
						} else {
							document.getElementById('expDateFilterSec').innerHTML += '<div id="expiryDate'+i
							+'">'+
							'<div id="expiryYear'+i+'">'+
							'<input type="checkbox" class="field-distance margin-field" onclick="SearchViewer.toGetFilteredSearchResult(&quot;expiryDate&quot;,&quot;'+dateobject['arr'][i]+'&quot;,this)" id="expiryDateYear'+i+'" value="'+dateobject['arr'][i]+'"><label '+dateobject['arr'][i]+' class="font-normal-weight">'+dateobject['arr'][i]+'</label>'+
							'<label class="font-normal-weight">&nbsp;('+dateobject['count'][dateobject['arr'][i]]+')</label>'+
							'<span class="glyphicon glyphicon-menu-up margin-field-20 bold" data-toggle="collapse" data-target="#expiryDateMonthsSec'+i+'" onClick="SearchViewer.changeIcon(this)"></span>'+
							'</div>'+
							'<div id="expiryDateMonthsSec'+i+'" class="collapse in margin-field-20">';
						}
					}
				}
				document.getElementById('expDateFilterSec').innerHTML += '</div></div><br/>';
			}
			//document.getElementById('expiryDateCount').innerHTML =' &nbsp;<strong>('+monthCount+' Options)</strong>';
			SearchViewer.expiryDateCountOption = monthCount;
			SearchViewer.getCheckedCount('expiryDate','expiryDateCount');
		} else {
			SearchViewer.getCheckedCount('expiryDate','expiryDateCount');
			document.getElementById('expiryDateSec').innerHTML = '';
		}
	},

	//to remove unchecked options from expiry date filter
	getExpiryCheckedData: function() {
		if(document.getElementById('expiryDate').value) {
			var tempExpiryDate = document.getElementById('expiryDate').value.split(',');
			Array.prototype.push.apply(SearchViewer.selected_expiryDate, tempExpiryDate);
			var index = SearchViewer.selected_expiryDate.indexOf('false');
			if(index>-1) {
				SearchViewer.selected_expiryDate.splice(index,1);
			}
			document.getElementById('expiryDate').value = SearchViewer.selected_expiryDate;
		}
	},

	//collapse if div having more then 5 element row
	collapseFiltersWithMoreThan5Options: function(filterRes) {
		try {
			if(SearchViewer.expiryDateCountOption>0) {
				if(SearchViewer.expiryDateCountOption>5) {
					SearchViewer.collapseById('expiryDateList');
					SearchViewer.changeIcon($('#expiryDateSec').children('span')[0]);
				} else {
					SearchViewer.expandById('expiryDateList');
				}
				if(SearchViewer.expiryFacetRessorted['arr']!=undefined) {
					SearchViewer.collapseExpiryDateFacetsIfAppropriate();
				}
			}
			if(filterRes.techspecNum.length>10) {
				SearchViewer.collapseById('techSpacList');
				SearchViewer.changeIcon($('#techSpecSec').children('span')[0]);
			} else {
				SearchViewer.expandById('techSpacList');
			}
			if(filterRes.companyName_facet.length>10) {
				SearchViewer.collapseById('manufacturerList');
				SearchViewer.changeIcon($('#manufacturerSec').children('span')[0]);
			} else {
				SearchViewer.expandById('manufacturerList');
			}
			if(filterRes.tssCertNo.length>10) {
				SearchViewer.collapseById('CertNumList');
				SearchViewer.changeIcon($('#certificateSec').children('span')[0]);
			} else {
				SearchViewer.expandById('CertNumList');
			}
		} catch(e) {
			console.log(e);
		}
	},

	collapseExpiryDateFacetsIfAppropriate: function() {
		for(let i = 0;i<SearchViewer.expiryFacetRessorted['arr'].length;i++) {
			for(let facet of SearchViewer.expiryFacetRes['expiryDateYear,expiryDateMonth']) {
				if(SearchViewer.expiryFacetRessorted['arr'][i]==facet.value) {
					if(facet.pivot.length>5) {
						SearchViewer.collapseById('expiryDateMonthsSec'+i);
						SearchViewer.changeIcon($('#expiryYear'+i).children('span')[0]);
					} else {
						SearchViewer.expandById('expiryDateMonthsSec'+i);
					}
				}
			}
		}
	},

	collapseById: function(id) {
		var hideAll = true;
		$('#'+id).collapse(hideAll ? 'hide' : 'show');
	},

	expandById: function(id) {
		var hideAll = false;
		$('#'+id).collapse(hideAll ? 'hide' : 'show');
	},

	// To compare the filtered values
	getCheckedFlag: function(
		tempArray,
		facetArrayValue
	) {
		if(facetArrayValue.indexOf(',')>0) {
			let retval = false;
			let tempfacetArray;
			let count = 0;
			tempfacetArray = facetArrayValue.split(',');
			for(let facet of tempfacetArray) {
				if($.inArray(facet, tempArray) == -1) {
					retval = false; //This line in a loop looks buggy.
				} else {
					retval = true;
					count++;
				}
			}
			if(count==tempfacetArray.length) {
				return retval;
			} // else, returns undefined! That seems like a possible bug.
		} else {
			return ($.inArray(facetArrayValue, tempArray) != -1);
		}
	},

	//get filter checked count and selected count
	getCheckedCount: function(
		searchByoptVal,
		fieldCountId
	) {
		if(searchByoptVal!='expiryDate') {
			if($('input[name="'+searchByoptVal+'"]:checked').length>0) {
				var checkedCount = $('input[name="'+searchByoptVal+'"]:checked').length;
				document.getElementById(fieldCountId).innerHTML = '';
				document.getElementById(fieldCountId).innerHTML = '&nbsp;<strong>('+checkedCount+' Selected)</strong>'
			} else {
				document.getElementById(fieldCountId).innerHTML = '';
				document.getElementById(fieldCountId).innerHTML = '&nbsp;<strong>('+SearchViewer[fieldCountId+'Option']+' Options)</strong>'
			}
		} else {
			var count = 0
			if($('input[name="expiryYearDate"]:checked').length>0) {
				count = $('input[name="expiryYearDate"]:checked').length
			}
			if($('input[name="expiryMonthDate"]:checked').length>0) {
				count += $('input[name="expiryMonthDate"]:checked').length
			}
			if(count>0) {
				document.getElementById(fieldCountId).innerHTML = '';
				document.getElementById(fieldCountId).innerHTML = '&nbsp;<strong>('+count+' Selected)</strong>'
			} else {
				if(document.getElementById(fieldCountId)!=undefined) {
					document.getElementById(fieldCountId).innerHTML = '';
					document.getElementById(fieldCountId).innerHTML = '&nbsp;<strong>('+SearchViewer[fieldCountId+'Option']+' Options)</strong>'
				}
			}
		}
	},

	// To get selected values from filter
	toGetFilteredSearchResult: function(
		searchByoptVal,
		searchTextVal,
		currentObj
	) {
		if(searchByoptVal == 'techspecNum') {
			SearchViewer.getCheckedCount(searchByoptVal,'techSpecCount');
			if(document.getElementById(searchTextVal).checked) {
				SearchViewer.selected_techspecNum.push(searchTextVal);
			} else {
				const index = SearchViewer.selected_techspecNum.indexOf(searchTextVal);
				if (index > -1) {
					SearchViewer.selected_techspecNum.splice(index, 1);
				}
			}
		}
		if(searchByoptVal == 'companyName_facet') {
			SearchViewer.getCheckedCount(searchByoptVal,'ManufacturerCount');
			if(document.getElementById(searchTextVal).checked) {
				SearchViewer.selected_companyName_facet.push(searchTextVal);
			} else {
				const index = SearchViewer.selected_companyName_facet.indexOf(searchTextVal);
				if (index > -1) {
					SearchViewer.selected_companyName_facet.splice(index, 1);
				}
			}
		}
		if(searchByoptVal == 'tssCertNo') {
			SearchViewer.getCheckedCount(searchByoptVal,'certificateCount');
			if(document.getElementById(searchTextVal).checked) {
				SearchViewer.selected_tssCertNo.push(searchTextVal);
			} else {
				const index = SearchViewer.selected_tssCertNo.indexOf(searchTextVal);
				if (index > -1) {
					SearchViewer.selected_tssCertNo.splice(index, 1);
				}
			}
		}
		if(searchByoptVal == 'expiryDate') {
			SearchViewer.getCheckedCount(searchByoptVal,'expiryDateCount');
			if(currentObj.checked) {
				SearchViewer.selected_expiryDate.push(searchTextVal);
				const index = SearchViewer.selected_expiryDate.indexOf('false');
				if (index > -1) {
					SearchViewer.selected_expiryDate.splice(index, 1);
				}
			} else {
				const index = SearchViewer.selected_expiryDate.indexOf(searchTextVal);
				if (index > -1) {
					SearchViewer.selected_expiryDate.splice(index, 1);
				}
			}
			$('#expiryDate').val(SearchViewer.selected_expiryDate);
		}
	},

	toApplyPaginationWithDot: function(
		currentPage,
		totalRecords,
		pageRows
	) {
		currentPage = parseInt(currentPage.replace(/[^0-9]+/ig,''));
		document.getElementById('paginationSec').innerHTML = '';
		var pageCount = 1;
		var tempVar = (totalRecords/pageRows).toFixed();
		if(parseInt(tempVar)<(parseInt(totalRecords)/parseInt(pageRows))) {
			pageCount = parseInt(tempVar)+1;
		} else if(parseInt(tempVar)==parseInt(totalRecords/pageRows)) {
			pageCount = parseInt(tempVar);
		} else {
			pageCount = parseInt(tempVar);
		}
		var numberOfPages = pageCount;
		SearchViewer.lastPage = numberOfPages;
		//TODO: Instead of writing HTML,
		//create HTML elements and attach listeners programmatically,
		//so that the calls are subject to TypeScript checking.
		var prev = '<li id="prePg"><a id="prePage" onclick="SearchViewer.showSearchRecords(&quot;prePage&quot;, true)" >&laquo;</a></li>';
		var paginationObj = prev;
		for(let i = 1; i<=numberOfPages; i++) {
			if(currentPage+4>=numberOfPages) {
				var diff = numberOfPages-currentPage;
				var pageNoShowWithDiff = 4-diff;
				if(i<currentPage-pageNoShowWithDiff) {
					if(i==1) {
						paginationObj += '<li  id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
					} else if (i==2) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" >..</a></li>';
						paginationObj += '<li id="pg'+i+'"><a style="display:none" id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
					} else {
						paginationObj += '<li style="display:none" id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
					}
				} else if (i==currentPage) {
					paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
				} else {
					if(i<currentPage+4-pageNoShowWithDiff) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true);">'+i+'</a></li>';
					} else if(i==numberOfPages) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true);">'+i+'</a></li>';
					} else {
						paginationObj += '<li style="display:none" id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
					}
				}
			} else {
				if(i<currentPage) {
					paginationObj += '<li style="display:none" id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
				} else if (i==currentPage) {
					paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
				} else {
					if(i<currentPage+4) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true);">'+i+'</a></li>';
					} else if(i==currentPage+4) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" >..</a></li>';
						paginationObj += '<li id="pg'+i+'"><a style="display:none" id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true);">'+i+'</a></li>';
					}
					else if(i==numberOfPages) {
						paginationObj += '<li id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true);">'+i+'</a></li>';
					} else {
						paginationObj += '<li style="display:none" id="pg'+i+'"><a id="page'+i+'" onclick="SearchViewer.toApplyActiveClass(&quot;page'+i+'&quot;);SearchViewer.showSearchRecords(&quot;page'+i+'&quot;, true );">'+i+'</a></li>';
					}
				}
			}
		}
		var next = '<li id="nxtPg"><a id="nextPage" onclick="SearchViewer.showSearchRecords(&quot;nextPage&quot;, true)">&raquo;</a></li>';
		paginationObj += next;
		document.getElementById('paginationSec').innerHTML = paginationObj;
	},

	//apply pagination at bottom
	toApplyPaginationAtBottom: function() {
		if(SearchViewer.clonePaginationWidgetToBottom) {
			document.getElementById('paginationSection').innerHTML = '';
			$('#paginationClone').clone(true).appendTo('#paginationSection');
			$('#paginationClone').position({
				my:			'right',
				at:			'right bottom',
				of:			'hr-seperator', // or $('#otherdiv')
				collision:	'fit'
			});
		}
	},

	//To calculate value for per page number of records with start and end values
	//This function is called with almost the same parameters
	//(a) when clicking on another page, when submitSearchForm == true
	//(b) when that new page loads, when submitSearchForm == false
	//submitSearchForm is boolean and only false in the call from getPageDetails().
	//currentPgId could also be 'prePage' or 'nextPage', passed to adjustForArrowNavigation
	showSearchRecords: function(
		currentPgId,
		submitSearchForm
	) {
		AppUI.setTimeOutForLoadingDiv(true);
		var perPageCount = $('#perPageSelect option:selected').text();
		SearchViewer.rows = perPageCount;
		var tempCurrentPage = parseInt(currentPgId.replace(/[^0-9]+/ig,''));
		if(tempCurrentPage==1 || SearchViewer.indexDocs.length<SearchViewer.rows) {
			SearchViewer.start = 0;
		}
		if(tempCurrentPage>SearchViewer.lastPage) {
			SearchViewer.currentPgNo = 1;
			$('#pageNo').val('page1');
			SearchViewer.start = 0;
		} else {
			SearchViewer.currentPgNo = $('#'+currentPgId).text();
			$('#pageNo').val('page'+SearchViewer.currentPgNo);
		}
		SearchViewer.adjustForArrowNavigation(currentPgId, perPageCount);
		SearchViewer.start = ((perPageCount * SearchViewer.currentPgNo)-perPageCount);
		$('#start').val(SearchViewer.start);
		SearchViewer.rows = perPageCount * SearchViewer.currentPgNo;
		if(SearchViewer.totalRecords == 0) {
			document.getElementById('displayText').innerHTML = 'Showing 0-0 of 0 results';
			document.getElementById('responseBody').innerHTML = 'No results to be displayed.';
			SearchViewer.clonePaginationWidgetToBottom = false;
			$('#paginationSection').hide();
			SearchViewer.start = 0;
			SearchViewer.rows = 0;
		} else {
			SearchViewer.updateShowingXofYResultsDisplay();
		}
		if(submitSearchForm == true) {
			AppUI.setTimeOutForLoadingDiv(true);
			SearchViewer.disableElementsAndDescendants($('#paginationSection').children());
			//GET is the default method so that's how it's submitted.
			document.getElementById('searchForm').submit();
		} else { //calling from getPageDetails()
			SearchViewer.toDisplayRecords(SearchViewer.indexDocs);
		}
		SearchViewer.toApplyPaginationWithDot($('#pageNo').val(),SearchViewer.totalRecords,perPageCount);
		SearchViewer.toApplyActiveClass($('#pageNo').val());
		SearchViewer.toApplyPaginationAtBottom();
	},

	adjustForArrowNavigation: function(
		currentPgId,
		perPageCount
	) {
		let usingArrowNavigation = false;
		if(currentPgId == 'prePage') {
			if(SearchViewer.previouspage != '1') {
				SearchViewer.currentPgNo = SearchViewer.previouspage - 1;
			} else {
				SearchViewer.currentPgNo = 1;
			}
			usingArrowNavigation = true;
		} else if(currentPgId == 'nextPage') {
			if(SearchViewer.previouspage != SearchViewer.lastPage) {
				SearchViewer.currentPgNo = parseFloat(SearchViewer.previouspage) + 1;
			} else {
				SearchViewer.currentPgNo = SearchViewer.lastPage;
			}
			usingArrowNavigation = true;
		}
		if(usingArrowNavigation) {
			var pageId = 'page' + SearchViewer.currentPgNo;
			SearchViewer.toApplyActiveClass(pageId);
			SearchViewer.toApplyPaginationWithDot(pageId,SearchViewer.totalRecords,perPageCount);
			SearchViewer.toApplyPaginationAtBottom();
		}
	},

	updateShowingXofYResultsDisplay: function() {
		if(SearchViewer.totalRecords < SearchViewer.rows) {
			document.getElementById('displayText').innerHTML = '<label>Showing '+(SearchViewer.start+1)+' - '+SearchViewer.totalRecords+' of '+SearchViewer.totalRecords+' results</label>';
		} else {
			document.getElementById('displayText').innerHTML = '<label>Showing '+(SearchViewer.start+1)+' - '+SearchViewer.rows+' of '+SearchViewer.totalRecords+' results</label>';
		}
	},

	//To apply active class for pagination
	toApplyActiveClass: function(pageid) {
		$('#paginationSec li.active').removeClass('active');
		$('#'+pageid).closest('li').addClass('active');
		$('#pageNo').val(pageid);
	},

	// To display search result records on webpage
	toDisplayRecords: function(indexDocs) {
		document.getElementById('responseBody').innerHTML = '';
		if(indexDocs.length == 0) {
			document.getElementById('responseBody').innerHTML = 'No results to be displayed.';
			document.getElementById('displayText').innerHTML = 'Showing 0-0 of 0 results'
		} else if(indexDocs.length<SearchViewer.start) {
			SearchViewer.start = 0;
		}
		SearchViewer.previouspage = SearchViewer.currentPgNo;
		$('input[value='+SearchViewer.searchByVal+']').attr('checked',true);
		var divElement = document.createElement('div');
		divElement.className = 'contents';
		for(let i = 0; i<indexDocs.length; i++) {
			if(!indexDocs[i].isPartQualHistorical) {
				SearchViewer.displayCurrentQualAsSearchResult(indexDocs[i], i, divElement);
			}
		}
	},

	getTechSpecSetToDisplay: function(resultRow) {
		if(!resultRow.techspecNum) {
			return '';
		}
		var techSpec = '';
		if(resultRow.techspecNum.length == 0) {
			techSpec = '--';
		}
		for(let j = 0; j < resultRow.techspecNum.length; j++) {
			const tempTechSpecUrl = resultRow.combinedTechSpec[j].split('_').slice(1)[0];
			const tempTechSpec = resultRow.combinedTechSpec[j].split('_').slice(0)[0];
			if($.inArray(tempTechSpec, resultRow.techspecNum) != -1) {
				if(tempTechSpecUrl != '#') {
					techSpec += '<a target="_blank" href="'+tempTechSpecUrl+'" style=" font-size: 11px;font-weight: normal;" id="tech_spec">'+resultRow.techspecNum[j]+'</a>';
				} else {
					techSpec += '<label style=" font-size: 11px;font-weight: normal;">'+resultRow.techspecNum[j]+'</label>';
				}
				if(j != resultRow.techspecNum.length-1) {
					techSpec += ', ';
				}
			}
		}
		return techSpec;
	},

	displayCurrentQualAsSearchResult: function(
		indexDoc,
		i,
		divElement
	) {
		const techSpec = SearchViewer.getTechSpecSetToDisplay(indexDoc);
		var partNumPopupValue = '';
		if(Common.isEmpty(indexDoc.partNum)) {
			indexDoc.partNum = '<div class="col-md-12" id="partNumSec"></div>';
			if(!Common.isEmpty(indexDoc.description)) {
				partNumPopupValue = indexDoc.description.replace("\"",'&quot;');
			}
		} else {
			partNumPopupValue = indexDoc.partNum.replace("\"",'&quot;');
			indexDoc.partNum = '<div class="col-md-12" id="partNumSec">'+
			'<div class="col-xs-7 col-md-4">'+
			'<label style="font-size: 11px;font-weight: normal; color:#999999; font-style: italic;" id="partNumLabel">Part Number:&nbsp;</label>'+
			'</div>'+
			'<div class="col-xs-5 col-md-8">'+'<a target="_blank" href="'+indexDoc.partsStandardsURL+'"id="partsStandardsURL">'+
			'<label style=" font-size: 11px;font-weight: normal;" id="part_number'+i+'" class="function-name">'+indexDoc.partNum+'</label>'+'</a>'+
			'</div>'+
			'</div>';
		}
		if(Common.isEmpty(indexDoc.description)) {
			indexDoc.description = '--';
		}
		if(Common.isEmpty(indexDoc.companyName)) {
			indexDoc.companyName = '--';
		}
		if(Common.isEmpty(indexDoc.companyAddress)) {
			indexDoc.companyAddress = '--';
		}
		if(Common.isEmpty(indexDoc.manufacturingRouteComment)) {
			indexDoc.manufacturingRouteComment = '--';
		}
		if(Common.isEmpty(indexDoc.firstQualified)) {
			indexDoc.firstQualified = '--';
		} else {
			indexDoc.firstQualified = DateUtils.getDateValue(indexDoc.firstQualified,true);
		}
		if(Common.isEmpty(indexDoc.expiryDate)) {
			indexDoc.expiryDate = '--';
		} else {
			var rangeFlag = DateUtils.isLinkExpiryDateWithinRange(DateUtils.convertSecondsToDate(indexDoc.expiryDate));
			indexDoc.expiryDate = DateUtils.getDateValue(indexDoc.expiryDate,true);
		}
		var techSpecPopupTitle = indexDoc.techspecNum;
		divElement.innerHTML +=
		'<div id="record'+i+'" class="content">'+
		'<div style="font-weight: normal !important; padding: 10px 0px;" class="row">'+
		'<div class="col-md-12">'+
		'<label>'+indexDoc.description+'&nbsp;<label style="display: inherit; font-size: smaller;"><a id="part_Childs'+i+'" onClick="SearchViewer.callPopupWindow(\''+partNumPopupValue+'\',\''+techSpecPopupTitle+'\',\'part_Childs'+i+'\',\'part_Childs_hidden'+i+'\')">'+
		' <em>(What this covers)</em></a></label></label>'+
		'</div>'+indexDoc.partNum+'<input type="hidden" id="part_Childs_hidden'+i+'"/>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Manufacturing Route Comment:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<label style=" font-size: 11px;font-weight: normal;" id="first_qual_date">'+indexDoc.manufacturingRouteComment+'</label>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">First Qualified date:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<label style=" font-size: 11px;font-weight: normal;" id="first_qual_date">'+indexDoc.firstQualified+'</label>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Expiry Date:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<div id="expDate"><label style=" font-size: 11px;font-weight: normal;" id="expiry_date" data-toggle="tooltip" data-placement="right">'+indexDoc.expiryDate+'</label>&nbsp;'+
		'</div>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Technical Specification:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+techSpec+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Manufacturer:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<label style=" font-size: 11px;font-weight: normal;" id="manufacturer_name">'+indexDoc.companyName+'</label>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Location(s):&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<label style=" font-size: 11px;font-weight: normal;" id="manufacturer_location">'+indexDoc.companyAddress+'</label>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12">'+
		'<div class="col-xs-7 col-md-4">'+
		'<label style=" font-size: 11px;font-weight: normal; color:#999999; font-style: italic;">Certificate TSSC #:&nbsp;</label>'+
		'</div>'+
		'<div class="col-xs-5 col-md-8">'+
		'<a  target="_blank" href="/certView?certNum='+indexDoc.tssCertNo.replace(' Addendum No ','&addendum=')+'" style=" font-size: 11px;font-weight: normal;" id="cert_number">'+indexDoc.tssCertNo+'</a>'+
		'</div>'+
		'</div>'+
		'<div class="col-md-12 hidden" id="adminLinksSec'+i+'">'+
		'<div class="col-xs-12">'+
		'<a href="/add-part-qual" id="addPartQualLink" style=" font-size: 11px;font-weight: normal; font-style: italic;"><u>Add Qualification</u></a>'+
		'&nbsp;&nbsp;&nbsp;&nbsp;'+
		'<a href="/add-cert" id="addCertLink" style=" font-size: 11px;font-weight: normal;font-style: italic;"><u>Add Certificate details</u></a>'+
		'</div>'+
		'</div>'+
		'</div>'+
		'<div class="hr-seperator"></div>'+
		'</div>';
		document.getElementById('responseBody').appendChild(divElement);
		if(rangeFlag==0) {
			$('#record'+i+' #expiry_date').addClass('inRangeExpiryDateColor');
			$('#record'+i+' #expDate').append('<img src="./images/AlertCircle.png" title="This qualification is due for renewal soon." data-toggle="tooltip" data-placement="right" width="10" height="10">');
		} else if(rangeFlag== -1) {
			$('#record'+i+' #expiry_date').addClass('BeforeRangeExpiryDateColor');
			$('#record'+i+' #expDate').append('<img src="./images/AlertTriangle.png" title="This qualification is overdue for renewal." data-toggle="tooltip" data-placement="right" width="10" height="10">');
		}
		$('[data-toggle="tooltip"]').tooltip();
		$('.tooltip').css('right',parseInt($('.tooltip').css('right')) +50 + 'px');
		SearchViewer.setAdminLinkVisibility(i);
		SearchViewer.showWhatThisCoversIfAppropriate(indexDoc, i);
	},

	setAdminLinkVisibility: function(i) {
		if(SearchViewer.adminAccountFlag === true) {
			SearchViewer.showLoggedInOnLoginButton();
			document.getElementById('adminLinksSec'+i+'').classList.replace('hidden','show');
		} else {
			document.getElementById('adminLinksSec'+i+'').classList.replace('show','hidden');
		}
	},

	showLoggedInOnLoginButton: function() {
		let loginButton = $('button#loginButton');
		loginButton.text('Logged in');
		loginButton.css('cursor', 'auto');
		loginButton.attr('title', 'Admin controls should be visible below.');
	},

	/**
	 * A split out from displayCurrentQualAsSearchResult for Sonar's cognitive complexity metric.
	 * @return {boolean}
	 */
	 shouldShowWhatThisCoversLink: function(indexDoc) {
		if(typeof indexDoc.QualifiedChildPartsHierarchy == 'undefined') {
			return false;
		}
		let showWhatThisCoversLink = false;
		var childListObject = JSON.parse(indexDoc.QualifiedChildPartsHierarchy);
		if(Object.keys(childListObject).length>0) {
			if(Object.keys(childListObject).length>1) {
				showWhatThisCoversLink = true;
			} else if(Object.keys(childListObject).length==1) {
				//For loop inside a conditional checking length 1 seems unnecessary
				for(let key in childListObject) {
					if(typeof childListObject[key].children != 'undefined') {
						showWhatThisCoversLink = true;
					}
				}
			}
		}
		return showWhatThisCoversLink;
	},

	showWhatThisCoversIfAppropriate: function(
		indexDoc,
		i
	) {
		const showWhatThisCoversLink = SearchViewer.shouldShowWhatThisCoversLink(indexDoc);
		if(showWhatThisCoversLink) {
			document.getElementById('part_Childs'+i).style.display = 'block';
		} else {
			document.getElementById('part_Childs'+i).style.display = 'none';
		}
		document.getElementById('part_Childs_hidden'+i).value = indexDoc.QualifiedChildPartsHierarchy;
	},

	//To remove space from search text
	//Called only in the submit handler for the #searchForm
	replaceValuesInSearchBar: function() {
		var searchtextFieldVal = $('#searchText').val();
		//No idea what these replacements actually do or why they were added.
		//The India team's function names suggest that the purpose is to trim the data
		//but this is clearly not just .trim().
		var searchtextFieldValTrimmed = searchtextFieldVal.replace(/^\s+/g, '').replace(/(\s+$)/g, '');
		searchtextFieldValTrimmed = searchtextFieldValTrimmed.replace(/[â€˜â€™]/g,"'");
		searchtextFieldValTrimmed = searchtextFieldValTrimmed.replace(/[â€œâ€]/g,'"');
		document.getElementById('searchText').value = searchtextFieldValTrimmed;
	},

	//toResetFilter() has two calling contexts:
		//1) as the 'Reset Filter' button click handler, and
		//2) as the 'Search' button click handler.
	toResetFilter: function() {
		document.getElementById('tssCertNo').value = false;
		$('input:checkbox').removeAttr('checked');
		$('#techspecNum').val('false');
		$('#companyName_facet').val('false');
		$('#tssCertNo').val('false');
		$('#expiryDate').val('false');
		$('input:checkbox').removeAttr('checked');
		$('input:checkbox').prop('checked',false);
		SearchViewer.disableElementsAndDescendants($('#paginationSection').children());
		document.getElementById('searchForm').submit();
	},

	// to set the default values
	setFieldValues: function(currentUrl) {
		var url = new URL(currentUrl);
		SearchViewer.searchTextVal = url.searchParams.get('searchText');
		SearchViewer.searchByVal = url.searchParams.get('searchByOpt');
		SearchViewer.rows = url.searchParams.get('rows');
		if(url.searchParams.get('start') == 0 ) {
			url.searchParams.set('pageNo','page1');
		}
		let pageNo = url.searchParams.get('pageNo');
		if(Common.isEmpty(pageNo)) {
			pageNo = 'page1';
		}
		$('#pageNo').val(pageNo);
		$('#searchText').val(SearchViewer.searchTextVal);
		$('#companyName_facet').val(url.searchParams.getAll('companyName_facet'));
		$('#techspecNum').val(url.searchParams.getAll('techspecNum'));
		$('#tssCertNo').val(url.searchParams.getAll('tssCertNo'));
		$('#expiryDate').val(url.searchParams.getAll('expiryDate'));
		$('input[value='+SearchViewer.searchByVal+']').attr('checked',true);
		$('#perPageSelect option[value='+SearchViewer.rows+']').attr('selected','selected');
	},

	disableElementsAndDescendants: function(arrayOfElementsToBeDisabled) {
		for (let elementToDisable of arrayOfElementsToBeDisabled) {
			elementToDisable.disabled = true;
			SearchViewer.disableElementsAndDescendants(elementToDisable.children);
		}
	},

	//To set number of rows from per page dropdown
	toSetRows: function(currentNoOfRows) {
		$('#perPageSelect option[value='+currentNoOfRows.value+']').prop('selected','selected');
		SearchViewer.rows = currentNoOfRows.value;
		$('#start').val(0);
		SearchViewer.start = 0;
		SearchViewer.disableElementsAndDescendants($('#paginationSection').children());
		document.getElementById('searchForm').submit();
	},

	//To get the data on change of page
	toGetPaginationData: function(currentPageNo) {
		$('input[name="start"]').val(SearchViewer.start);
		$('#start').val(SearchViewer.start);
		SearchViewer.disableElementsAndDescendants($('#paginationSection').children());
		document.getElementById('searchForm').submit();
	},

	//Called just from the searchForm submit handler.
	setLoadingAndResetPagination: function() {
		AppUI.setTimeOutForLoadingDiv(true);
		$('#pageNo').val('page1');
		$('#start').val(0);
		SearchViewer.start = 0;
	},

	initializePage: function() {
		$(document).on('submit','#searchForm',function (e) {
			SearchViewer.setLoadingAndResetPagination();
			SearchViewer.replaceValuesInSearchBar();
			SearchViewer.disableElementsAndDescendants($('#paginationSection').children());
		});
	},

	onFilterSpanClick: function(filterType) {
		let filterBtn = null;
		if(filterType == 'manufacture') {
			filterBtn = $('#manufacturerSec').find('#spn_filter_manufacure');
		} else if(filterType == 'techSpec') {
			filterBtn = $('#techSpecSec').find('#spn_filter_tech_spec');
		} else if(filterType == 'certificate') {
			filterBtn = $('#certificateSec').find('#spn_filter_certificate');
		} else if(filterType == 'expiryDate') {
			filterBtn = $('#expiryDateSec').find('#spn_filter_expiry_date');
		}
		if(filterBtn != null) {
			SearchViewer.changeIcon(filterBtn[0]);
		}
	},

	changeIcon: function(currentPanel) {
		try {
			var aa = $(currentPanel).attr('data-target');
			if(currentPanel.classList.contains('glyphicon-menu-down')) {
				currentPanel.classList.remove('glyphicon-menu-down');
				currentPanel.classList.add('glyphicon-menu-up');
				$(aa).on('hidden.bs.collapse', function() {
					if(!$(aa).hasClass('in')) {
						currentPanel.classList.remove('glyphicon-menu-up');
						currentPanel.classList.add('glyphicon-menu-down');
					}
				});
			} else {
				currentPanel.classList.add('glyphicon-menu-down');
				currentPanel.classList.remove('glyphicon-menu-up');
				$(aa).on('shown.bs.collapse', function() {
					if($(aa).hasClass('in')) {
						currentPanel.classList.remove('glyphicon-menu-down');
						currentPanel.classList.add('glyphicon-menu-up');
					}
				});
			}
		} catch(e) {
			console.error(e);
		}
	},

	addParentListToPopUp: function(
		tempObject2,
		arrayTemp,
		key,
		grandParent
	) {
		for(let key2 in tempObject2) {
			if(arrayTemp.indexOf(key2)<0 && !SearchViewer.checkAvailInObjectChild(key2,tempObject2)) {
				var tempVal;
				//tempVal use to split part number and its url
				tempVal = key2.split('_partUrl_');
				//check the length of the part Number so we can show full name OR '...' above 35 characters
				if(tempVal[0].length>35) {
					tempVal[0] = tempVal[0].substring(0,35)+'...';
				}
				tempVal[1] = (tempVal[1]=='#' || tempVal[1]=='') ? '#' : tempVal[1];
				// avoid dublicate data and replace quote with '&Prime;'
				if(key2.split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')!=grandParent.replace("\"",'&Prime;')) {
					arrayTemp.push(key2);
					if(tempVal[1]!='#' && tempVal[1]!='') {
						$('#addParentChild').append('<div class="popupUlMargin" ><div><span class="parentBold divBullet" title="'+key2.split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')+'"><a href="'+tempVal[1]+'" target="_blank">'+tempVal[0]+'</a></span><span  class="glyphicon glyphicon-menu-up margin-field-20 bold" id="#glyphiconSpanId'+key+'" data-toggle="collapse" data-target="#addChild'+key+'" onClick="SearchViewer.changeIcon(this)"></span></div><div class="collapse in" id="addChild'+key+'"></div></span></div>');
					} else {
						$('#addParentChild').append('<div class="popupUlMargin" ><div><span class="parentBold divBullet" title="'+key2.split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')+'">'+tempVal[0] +'</span><span id="glyphiconSpanId'+key+'" class="glyphicon glyphicon-menu-up margin-field-20 bold" data-toggle="collapse" data-target="#addChild'+key+'" onClick="SearchViewer.changeIcon(this)"></span></div><div class="collapse in" id="addChild'+key+'"></div></span></div>');
					}
				} else {
					$('#addParentChild').append('<ul class="popupUlMargin" ><div class="collapse in" id="addChild'+key+'"></div></span></ul>');
				}
				if(tempObject2[key2].children!=undefined) {
					if(tempObject2[key2].children.length>5) {
						$('#glyphiconSpanId'+key).removeClass('glyphicon-menu-up');
						$('#glyphiconSpanId'+key).addClass('glyphicon-menu-down');
					}
					for(let i = 0;i<tempObject2[key2].children.length;i++) {
						var tempInnerVal;
						tempInnerVal = tempObject2[key2].children[i].split('_partUrl_');
						if(tempInnerVal[0].includes(SearchViewer.uniquePartIdentifier)) {
							tempInnerVal[0] = tempInnerVal[0].split(SearchViewer.uniquePartIdentifier)[0];
						}
						if(tempInnerVal[0].length>30) {
							tempInnerVal[0] = tempInnerVal[0].substring(0,30)+'...';
							tempInnerVal[1] = (tempInnerVal[1]=='#' ||tempInnerVal[1]=='') ? '#' : tempInnerVal[1];
						} else {
							tempInnerVal[1] = (tempInnerVal[1]=='#' ||tempInnerVal[1]=='') ? '#' : tempInnerVal[1];
						}
						if(tempInnerVal[1]!='#' && tempInnerVal[1]!='') {
							$('#addChild'+key).append('<div class="popupUlMargin"><div><span title="'+tempObject2[key2].children[i].split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')+'" id=spanchild'+key+i+'> <a href="'+tempInnerVal[1]+'" target="_blank">'+tempInnerVal[0]+'</a><span></div><div class="collapse in" style="padding" id=child'+key+i+' ></div></div>');
						} else {
							$('#addChild'+key).append('<div class="popupUlMargin"><div> <span title="'+tempObject2[key2].children[i].split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')+'" id=spanchild'+key+i+'>'+tempInnerVal[0]+'<span></div><div class="collapse in" style="padding" id=child'+key+i+' ></div></div>');
						}
						arrayTemp.push(tempObject2[key2].children[i]);
						if(
							tempObject2[tempObject2[key2].children[i]]!=undefined && tempObject2[tempObject2[key2].children[i]].children!=undefined
							&& tempObject2[tempObject2[key2].children[i]].children.length > 0
						) {
							let glyphiconArrowDirection;
							if(tempObject2[tempObject2[key2].children[i]].children.length>5) {
								$('#child'+key+i).removeClass('in');
								glyphiconArrowDirection = 'glyphicon-menu-down';
							} else {
								$('#child'+key+i).addClass('in');
								glyphiconArrowDirection = 'glyphicon-menu-up';
							}
							$('#spanchild'+key+i).addClass('parentBold');
							$('#spanchild'+key+i).append('<span class="glyphicon '+ glyphiconArrowDirection +' margin-field-20 bold" data-toggle="collapse" data-target="#child'+key+i+'" onClick="SearchViewer.changeIcon(this)"/>');
							SearchViewer.addChildListToPopUp(tempObject2,tempObject2[key2].children[i],'child'+key+i,arrayTemp,key);
						}
					}
				}
			}
		}
	},

	checkAvailInObjectChild: function(
		val,
		objectTemp
	) {
		var flag = false;
		for(let key in objectTemp) {
			if(objectTemp[key].children!=undefined) {
				if(objectTemp[key].children.indexOf(val)!=-1) {
					flag = true;
					break
				}
			}
		}
		return flag;
	},

	addChildListToPopUp: function(
		tempObject2,
		comparekey,
		ithelement,
		arrayTemp,
		key
	) {
		for(let key2 in tempObject2) {
			if(comparekey==key2) {
				//$('#child'+ithelement+1).append('<div class="li-align"><ul><span class="abc" id="spanchild'+key2.toString().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>" "\{\}\[\]\\\/]/gi, '')+'">'+key2+'<span>');
				for(let child of tempObject2[key2].children) {
					var tempVal;
					tempVal = child.split('_partUrl_');
					if(tempVal[0].includes('_partId_')) {
						tempVal[0] = tempVal[0].split('_partId_')[0];
					}
					if(tempVal[0].length>30) {
						tempVal[0] = tempVal[0].substring(0,30)+'...';
					}
					tempVal[1] = (tempVal[1]=='#' ||tempVal[1]=='') ? '#' : tempVal[1];
					const spanId = child.toString().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>" "\{\}\[\]\\\/]/gi, '');
					if(arrayTemp.indexOf(child)<0 || tempVal[0] != '') {
						let divElement = '<div class="li-align"><div><span class="divBullet" title="'+child.split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace("\"",'&Prime;')+'" id="innerSpan'+spanId+'">';
						if(tempVal[1]!='#' && tempVal[1]!='') {
							divElement = divElement + '<a href="'+tempVal[1]+'" target="_blank">'+tempVal[0]+'</a></span></div><div class="collapse in" id="spanchild'+spanId+'"></div></div>';
						} else {
							divElement = divElement+tempVal[0]+'</span></div><div class="collapse in" id="spanchild'+child.toString().split('_partUrl_')[0].split(SearchViewer.uniquePartIdentifier)[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>" "\{\}\[\]\\\/]/gi, '')+'"></div></div>';
						}
						$('#'+ithelement).append(divElement);
						arrayTemp.push(child);
					}
					if(
						tempObject2[child]!=undefined && tempObject2[child].children!=undefined
						&& tempObject2[child].children.length > 0
					) {
						let glyphiconArrowDirection;
						if(tempObject2[child].children.length>5) {
							glyphiconArrowDirection = 'glyphicon-menu-down';
							$('#spanchild'+spanId).removeClass('in');
						} else {
							$('#spanchild'+spanId).addClass('in');
							glyphiconArrowDirection = 'glyphicon-menu-up';
						}
						$('#innerSpan'+spanId).append('<span class="glyphicon ' + glyphiconArrowDirection + ' margin-field-20 bold" data-toggle="collapse" data-target="#spanchild'+spanId+'" onClick="SearchViewer.changeIcon(this)"/>');
						$('#innerSpan'+spanId).addClass('parentBold');
						SearchViewer.addChildListToPopUp(tempObject2,child,'spanchild'+spanId,arrayTemp,key);
					}
				}
				//$('#child'+ithelement+1).append('</ul><div>');
			}
		}
	},

	callPopupWindow: function(
		popupPartNumTitle,
		popuptechSpecTitle,
		tempchildId,
		objectFieldHiddenId
	) {
		document.getElementById('popupTitlewithPartnum').innerHTML = ''
		// Get the modal
		var modal = document.getElementById('myModal');
		// Get the button that opens the modal
		var objectFieldId = document.getElementById(objectFieldHiddenId);
		var tempid = document.getElementById(tempchildId);
		// Get the <span> element that closes the modal
		var span = document.getElementsByClassName('close')[0];
		// When the user clicks the button, open the modal
		modal.style.display = 'block';
		document.getElementById('addParentChild').innerHTML = ''
		if(objectFieldId.value!=undefined) {
			var childObject = JSON.parse(objectFieldId.value);
			document.getElementById('popupTitlewithPartnum').innerHTML = '<label>A qualification to <em>'+popupPartNumTitle.replace("\"",'&Prime;')+' ('+popuptechSpecTitle+')</em> covers the following parts, which are not considered substitutes for each other:</label>';
			//to show the parent child list on model
			SearchViewer.addParentListToPopUp(childObject,[],tempid.id,popupPartNumTitle);
		}
		// When the user clicks on <span> (x), close the modal
		span.onclick = function() {
			modal.style.display = 'none';
		}
		// When the user clicks anywhere outside of the modal, close it
		window.onclick = function(event) {
			if (event.target == modal) {
				modal.style.display = 'none';
			}
		}
	},

	//Has only one calling context, after header is loaded.
	runOnDomReady: function() {
		SearchViewer.initializePage();
		SearchViewer.getPageDetailsIfResDataPresent();
		SearchViewer.addListenersOnLoad();
		SearchViewer.setupAsHomepageIfAppropriate();
	},

	//getPageDetailsIfResDataPresent() has two main calling contexts:
		//1) when the DOM is ready
		//for manipulation IFF document.getElementById('resData').value is not one of these three strict strings: {'noData', '', 'undefined'}
		//2) In checkMetaMaskAccount() after some web3 and initiation, if the firstAccount returned by login is '',
		//or if the firstAccount is not an empty string, after determining who Company.owner is in allowAdminFunction().
	getPageDetailsIfResDataPresent: function() {
		const resData = document.getElementById('resData')?.value;
		if(
			resData !== 'noData' &&
			resData !== '' &&
			resData !== 'undefined'
		) {
			SearchViewer.getPageDetails();
		} else {
			AppUI.setTimeOutForLoadingDiv(false);
		}
	},

	setupAsHomepageIfAppropriate: function() {
		var url = window.location.search;
		if (url == '') {
			AppUI.setTimeOutForLoadingDiv(true);
			$('#displayText').hide();
			$('#paginationSec').hide();
			$('#paginationClone').hide();
			var filterUrl = './search?searchText=&onlyforFacet=onlyforFacet';
			$.ajax({
				url: filterUrl,
				method: 'GET',
				async: false,
				success: function(responseDatatemp) {
					var jsonObject = JSON.parse(responseDatatemp);
					SearchViewer.showFilterOptions(true);
					SearchViewer.expiryFacetRes = jsonObject.facet_counts.facet_pivot;
					SearchViewer.toDisplayFilterResult(jsonObject.facet_counts.facet_fields);
					AppUI.setTimeOutForLoadingDiv(false);
				},
				error: function(err) {
					console.log(err)
				}
			});
		}
	},

	showFilterOptions: function(shouldShowFilterOptions) {
		if(shouldShowFilterOptions) {
			document.getElementById('filterOptSec').classList.replace('hidden','show');
			document.getElementById('emptyFilterNote').classList.replace('show','hidden');
			document.getElementById('applyBtn').disabled = false;
			document.getElementById('resetBtn').disabled = false;
		} else {
			document.getElementById('filterOptSec').classList.replace('show','hidden');
			document.getElementById('emptyFilterNote').classList.replace('hidden','show');
			document.getElementById('applyBtn').disabled = true;
			document.getElementById('resetBtn').disabled = true;
		}
	},

	addListenersOnLoad: function() {
		$('.checkHeight').on('shown.bs.collapse',function() {
			SearchViewer.setFilterButtonsVisibilityByHeight();
		});
		$('.checkHeight').on('hidden.bs.collapse',function() {
			SearchViewer.setFilterButtonsVisibilityByHeight();
		});
		SearchViewer.addSubmitOnEnterListener();
	},

	setFilterButtonsVisibilityByHeight: function() {
		var elmnt = document.getElementById('filterOptSec');
		var height = elmnt.offsetHeight;
		if(height<=600) {
			$('#applyButton').addClass('hidden');
			$('#resetButton').addClass('hidden');
		} else {
			$('#applyButton').removeClass('hidden');
			$('#resetButton').removeClass('hidden');
		}
	},

	//Split out for Sonar's cognitive complexity measure
	addSubmitOnEnterListener: function() {
		window.addEventListener('keydown', function(e) {
			if((e.code == 'Enter') || (e.code == 'NumpadEnter')) {
				e.preventDefault();
				$('#searchForm').trigger('submit');
				return false;
			}
		});
	},

	checkMetaMaskAccount: function() {
		Promise.resolve().then(function () {
			return Common.initWeb3(Web3);
		}).then(function(web3object) {
			globalThis.web3 = web3object;
			return Common.login(web3object);
		}).then(function(firstAccount) {
			Common.initContracts(ContractLister.availableContracts).then(function() {
				if(firstAccount == '') {
					SearchViewer.getPageDetailsIfResDataPresent();
				} else {
					//allowAdminFunction calls getPageDetailsIfResDataPresent,
					//as long as it gets something back from a
					//chain call to Company.owner
					SearchViewer.allowAdminFunction(firstAccount);
				}
			}).catch(function(e) {
				console.log(e);
			});
		}).catch(function(e) {
			console.log(e);
		});
	},
}
