//From TS200 India team codebase.
//This file appears to have been originally copied from the npm package alphanumeric-sort
//https://www.npmjs.com/package/alphanumeric-sort
//which is described by the author as 'quick & dirty', 'not probably very fast',
//and '(To be honest, I haven't really tested.)' despite initial publication
//and last update Oct 29 2013.
//The package has NO LICENSE.
//The author, anttisykari (https://www.npmjs.com/~anttisykari) has no direct
//links out or GitHub, but seems very likely to be the same as Helsinki, Finland based
//Antti Kissaniemi, https://www.linkedin.com/in/anttisykari/
//who is on GitHub (mostly in private repos) at https://github.com/anttikissa
//and has an email address of antti.kissaniemi@gmail.com.

function compare(a: number | string, b: number | string) {
	if (typeof a == 'string' && typeof b == 'string') {
		return alphanumCompare(a, b);
	} else {
		if (a < b) {
			return -1;
		} else if(a > b) {
			return 1;
		} else {
			return 0;
		}
	}
}

const alphanumCompare = function(a?: number | string, b?: number | string) : number {
	if(a!=undefined && a!='' && b!=undefined && b!='') {
		var reA = /[^a-zA-Z]/g;
		//regex to remove all special char and all a-z charecter
		var reN = /[^0-9\.]/g;
		a = typeof a === 'string' ? a.replace('Addendum No','.').toLowerCase() : a.toString();
		b = typeof b === 'string' ? b.replace('Addendum No','.').toLowerCase() : b.toString();
		var aA = a.replace(reA, '');
		var bA = b.replace(reA, '');
		if(aA === bA) {
			var aN = parseFloat(a.replace(reN, ''));
			var bN = parseFloat(b.replace(reN, ''));
			if(isNaN(aN) || isNaN(bN)) {
				return a.localeCompare(b);
			}
			return basicCompare(aN, bN);
		} else {
			return a.localeCompare(b);
		}
	}
	return 0; //if nothing else; default that the two values being compared are equal. Default return type required by TS.
}

const basicCompare = function(aN: number | string, bN: number | string) {
	if(aN === bN) {
		return 0;
	} else if(aN > bN) {
		return 1;
	} else {
		return -1;
	}
}

export const AlphaNumericSorter = {
	alphanumCompare,
	basicCompare,
}
