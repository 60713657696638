//The other config file has properties needed for client-side execution AND server-side execution:
import {sharedConfiguration} from './sharedConfiguration.js';
//This file is intended for parameters ONLY needed for server-side execution.
export const serverConfiguration : ServerConfiguration = {
    alsoLogToConsole: false, //not fully implemented across codebase
    email: {
        'sendTo': 'Geraint.Williams@sae-itc.org', //comma-separate multiple recipients
        'sendToBCC': 'TS200group@fullsight.org, Arijith.Roy@sae.org', //comma-separate multiple recipients
        'sendErrorLogsTo': 'TS200group@fullsight.org',
        'sendFrom': 'TS200@sae-itc.org',
        'port': 25,
        'host': 'mail-relay.sae.org',
    },
    servers: {
        blockchain: {
            'host': '127.0.0.1',
        },
        migrationsNode: {
            //If using a migration node different from the otherwise-used access
            //node, these values can be changed away from their current references.
            'ip': sharedConfiguration.ipConfig.ipAddress,
            'port': sharedConfiguration.servers.accessNode.port,
            'networkID': sharedConfiguration.servers.accessNode.networkID,
        },
        interfaceApp: {
            'port': '7540',
            'useHTTP': true,
        },
        solr: {
            'ip': sharedConfiguration.ipConfig.ipAddress,
            'port': '8983',
        },
        explorer: {
            'port': '7541',
            'logs': '/debug/logs/explorerServerLogs/',
        },
    },
    paths: {
        //Note: Iff the label ends with 'Logs' the path will be active for the
        //logsCleaner object / cleanupLogs command.
        'api1Logs': '/debug/logs/api1Logs/',
        'emailLogs': '/debug/logs/emailLogs/',
        'logsCleanerLogs': '/debug/logs/logsCleanerLogs/',
        'migrationLogs': '/debug/logs/migrationLogs/',
        'nodeServerLogs': '/debug/logs/nodeServerLogs/',
        'reminderPDFs': '/ts200/archive/',
        'solrDumps': '/ts200/data/fileCaches/ts200l/solrDumps/',
        'solrLogs': '/debug/logs/SolrSearchLogs/',
        'solrUpdateLogs': '/debug/logs/solrUpdateLogs/',
        'eventLogs': '/debug/logs/events/',
        'eventSyncerLogs': '/debug/logs/events/syncerLogs/',
        'dataCacheLogs': '/debug/logs/dataCacheLogs/',
        'dataCacheData': '/ts200/data/fileCaches/',
        'searchLogReportArchive': 'reports/searchLogReport/archive/',
        'yearOverYearReportArchive': 'reports/yearOverYear/archive/',
        'adminFiles': '/ts200/data/adminFiles/',
    },
    initialAdmins: [
        '0x59772c382FaeCB193663c7019b58029702b1E0a1'
    ],
    schedule: {
        //Items in this schedule are given in cronTime,
        //Warrendale time zone (matches NYC).
        //2-3am daily is not used as a start time for anything to avoid DST complications.

        //These values are intended for production use:
        sendReminderEmails: '00 00 08 01 * * ',
        sendYearOverYearReport: '00 30 09 02 Jan *',
        //every 2 hrs; 1 min earlier for no DST funny business
        cacheRotation: '0 59 1,3,5,7,9,11,13,15,17,19,21,23 * * *',
        //Set solrRebuilds to empty string to not run Solr rebuilds in Node (if externally managed by Appworx etc.)
        solrRebuilds: '00 00 0,4,6,8,10,12,14,16,18,20,22 * * *',
        clearOldFromTradDBPendingEventQueue: '0 45 3 * * *', //3:45a
        clearOldFromFileCachePendingEventQueue:  '0 45 5 * * *', //5:45a
        pendingEventQueueMinimumEventTimeoutAge: 24*60*60*1000, //in ms
        cleanupLogs: '', //11:40p daily. Set to empty string to not run this job automatically.
        alertNonEmptyErrorLogFilesYoungerThan: 24*60*60*1000, //in ms, based on file modification date
        deleteEmptyLogFilesOlderThan: 24*60*60*1000, //in ms, based on file modification date
        deleteNonEmptyLogFilesOlderThan: 30*24*60*60*1000, //90 days in ms, based on file modification date
    },
}


