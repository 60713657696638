import { Common } from './common.js';
import type BigNumber from 'bignumber.js';
import BN from 'bn.js';
export type ConvertibleToNumber = BigNumber | BN | string | number;
type ConvertibleToDate = Date | number[] | number | BN | string | {year: number, month: number, date ?: number};
export const DateUtils = {
	//Poor naming: monthName is a boolean flag switching output format.
	//All times in this application should be UTC.
	getDateValue: function(dateInSeconds: ConvertibleToNumber, monthName: boolean) {
		if(dateInSeconds!=0 && dateInSeconds!='' && dateInSeconds!=undefined) {
			//var newDate = DateUtils.setDateToMidOfMonthAndDay(new Date(dateInSeconds*1000));
			//to set the date to 15 and 12 pm of the day
			dateInSeconds = DateUtils.convertBigNumberToNumber(dateInSeconds);
			var newDate = DateUtils.setDateToMidOfMonthAndDay(new Date((dateInSeconds*1000))); //Type conversions without safety b/c not sure if param is actually a dateInSeconds.
			// to get the short month Name only
			let month;
			if(monthName) {
				month = DateUtils.getMonthName(newDate.getUTCMonth(), true); //String in the set 'Jan'...'Dec'
			} else {
				month = (newDate.getUTCMonth()+1).toString().padStart(2, '0'); //String in the set '01'...'12'
			}
			//to get the year only
			var year = newDate.getUTCFullYear();
			if(monthName) {
				return month+' '+year;
			} else {
				return year+'-'+month;
			}
		} else {
			return '';
		}
	},

	formatDateMonthYear: function(date : Date, useShortMonths : boolean) {
		var monthIndex = date.getUTCMonth();
		var year = date.getUTCFullYear();
		return DateUtils.getMonthName(monthIndex, useShortMonths) + ' ' + year;
	},

	getMonthName: function(monthIndex : number, useShortMonths : boolean) {
		//Can use const above instead of var here.
		var monthNames;
		if(useShortMonths) {
			monthNames = [
				'Jan', 'Feb', 'Mar', 'Apr',
				'May', 'Jun', 'Jul', 'Aug',
				'Sep', 'Oct', 'Nov', 'Dec'
			];
		} else {
			monthNames = [
				'January', 'February', 'March', 'April',
				'May', 'June', 'July', 'August',
				'September', 'October', 'November', 'December'
			];
		}
		return monthNames[monthIndex];
	},

	getMidMonthDateInUTCSeconds: function(longerdate?: Date | number | string) {
		//check the date is valid
		if(longerdate!=0 && longerdate!='' && longerdate!=undefined) {
			var newDate = DateUtils.setDateToMidOfMonthAndDay(new Date(longerdate));
			return (newDate.getTime()/1000); //This function returns a UTC timestamp in SECONDS
		} else {
			return 0;
		}
	},

	convertSecondsToDate: function(dateInSeconds?: ConvertibleToNumber) {
		//check the date is valid
		if(dateInSeconds!=0 && dateInSeconds!='' && dateInSeconds!=undefined) {
			const dateInSecondsNumber = DateUtils.convertBigNumberToNumber(dateInSeconds);
			return DateUtils.setDateToMidOfMonthAndDay(dateInSecondsNumber*1000); //Type conversions without safety b/c not sure if param is actually a dateInSeconds.
		} else {
			return new Date(0);
		}
	},

	convertBigNumberToNumber: function(bigNumber: ConvertibleToNumber) {
		if((typeof bigNumber == 'object') || (typeof bigNumber == 'string')) {
			return Number(bigNumber.toString());
		} else {
			return bigNumber;
		}
	},

	//dateObject: parameter should be date
	setDateToMidOfMonthAndDay: function(dateObject: Date | number | string) {
		// to set date to 15 on the month, and time to noon.
		//Using UTC to simplify code with cross-browser compatibility.
		//Is Date re-constructor really necessary?
		//Unsure if input parameter is actually a Date object,
		//and/or if it's used elsewhere such that the modifications would be problematic.
		var modifiedDate = new Date(dateObject);
		modifiedDate.setUTCDate(15);
		modifiedDate.setUTCHours(12,0,0,0);//give date in milliseconds formate
		return new Date(modifiedDate);
	},

	convertToDate: function(d: ConvertibleToDate) { //Adapted from https://stackoverflow.com/a/497790
		// Converts the date in d to a date-object. The input can be:
		// a date object: returned without modification
		// an array	 : Interpreted as [year,month,day]. NOTE: month is 0-11.
		// a number	 : Interpreted as number of milliseconds
		//				since 1 Jan 1970 (a timestamp)
		// a string	 : Any format supported by the javascript engine, like
		//				'YYYY/MM/DD', 'MM/DD/YYYY', 'Jan 31 2009' etc.
		//an object	 : Interpreted as an object with year, month and date
		//				attributes.  **NOTE** month is 0-11.
		if(d instanceof Date) {
			return d;
		} else if(Array.isArray(d)) {
			return new Date(d[0],d[1],d[2]);
		} else if(BN.isBN(d)) {
			return new Date(d.toNumber());
		} else if(typeof d === 'number') {
			return new Date(d);
		} else if(typeof d === 'string') {
			return new Date(d);
		} else if(typeof d === 'object') {
			return new Date(d.year,d.month,d.date);
		} else {
			throw new TypeError();
		}
	},

	/**
	 * @throws {TypeError} from convertToDate()
	 */
	compare: function(a: ConvertibleToDate, b: ConvertibleToDate) { //Adapted from https://stackoverflow.com/a/497790
		// Compare two dates (could be of any type supported by the convertToDate
		// function above) and returns:
		//  -1 : if a < b
		//   0 : if a = b
		//   1 : if a > b
		// NaN : if a or b is an illegal date
		const aTimestamp = this.convertToDate(a).valueOf();
		const bTimestamp = this.convertToDate(b).valueOf();
		if( isFinite(aTimestamp) && isFinite(bTimestamp) ) {
			if(aTimestamp > bTimestamp) {
				return 1;
			} else if (aTimestamp < bTimestamp) {
				return -1;
			} else {
				return 0;
			}
		} else {
			return NaN;
		}
	},

	inRange: function(d: ConvertibleToDate, start: ConvertibleToDate, end: ConvertibleToDate) { //Adapted from https://stackoverflow.com/a/497790
		// Checks if date in d is between dates in start and end.
		// Returns a boolean.
		//	true  : if d is between start and end (inclusive)
		//	false : if d is before start or after end OR if one or more of the dates is illegal.
		try {
			const dNumeric = this.convertToDate(d).valueOf();
			const startNumeric = this.convertToDate(start).valueOf();
			const endNumeric = this.convertToDate(end).valueOf();
			return ( ( isFinite(dNumeric) && isFinite(startNumeric) && isFinite(endNumeric) ) ? startNumeric <= dNumeric && dNumeric <= endNumeric : false );
		} catch (conversionError) {
			return false;
		}
	},

	isLinkExpiryDateWithinRange: function(expDate: ConvertibleToDate) {
		var now = Date.now(); //milliseconds elapsed since Unix epoch UTC
		var today = new Date(now);
		today = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth()));
		var endDate = new Date(now); //not re-calling Date.now() as that will have changed slightly.
		endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth())); //mirroring poor structure above
		endDate.setUTCMonth(endDate.getUTCMonth() + 3); //not combining w/above line due to better-documented out-of-bounds handling in setUTCMonth
		//Should the end date be set to end of month as well? (separate issue)
		var checkForRange = DateUtils.inRange(expDate,today,endDate);
		if(checkForRange) {
			return 0;//orange
		} else {
			try {
				var compareExpCurrentDate = DateUtils.compare(expDate,today);
				var compareExpEndDate = DateUtils.compare(expDate,endDate);
			} catch(conversionError) {
				return undefined;
			}
			if(compareExpCurrentDate == -1) {
				return -1; //red
			} else {
				if(compareExpEndDate == 1) {
					return 1; //black
				}
			}
		}
	}
}
